import { Delivery, PRICE_TYPES } from '@bytel/bytel-sales';
import { Model } from '@models/model';
import { add, isSameDay } from 'date-fns';
import { Type } from 'class-transformer';

export class DeliveryInfoModel extends Model{

    public gencode: string;
    public libelle: string;
    @Type(()=>Date)
    public estimatedDeliveryDate: Date;
    @Type(()=>Date)
    public lateEstimatedDeliveryDate: Date;
    public price: number;
    public idVirtuel: number;
    public product: Delivery;

    constructor(data: Partial<DeliveryInfoModel>) {
        super(data);
        if (!data){return;}
        this.product = new Delivery(Delivery.prefillData({
            typePrix: PRICE_TYPES.Today,
            libelle: this.libelle,
            prix: this.price,
            gencode: this.gencode,
        }));
    }

    public isTomorrowDeliverable(): boolean {
        return isSameDay(add(new Date(),{ days: 1 }),this.estimatedDeliveryDate);
    }

}

