<p #elm class="price" [ngClass]="{'is-main-price': isMainPrice, 'is-inlined': isInlined, 'has-description': hasIcon, 'has-text-grey-dark': isDiscountPrice, 'is-greyed': isDiscountPrice,'is-striked':isDiscountPrice}" >
  <span class="main">@if (isAdditionalPrice) {
    <span>+</span>
  }{{mainPrice}}</span>

  <span class="price-details" >
    <span class="cents">
      <span>€{{centimePrice}}
        @if (isWithoutTax && isInlined) {
          <span>HT</span>
        }
        @if ((isWithoutTax && !isInlined) || asterix) {
          <sup>{{(isWithoutTax && !isInlined) ? 'HT' : ''}} {{asterix ? '('+asterix+')':''}}</sup>
        }</span>
        @if (hasIcon) {
          <span class="icon">
            <span [ngClass]="iconClassNames" (click)="onClickIcon()"></span>
          </span>
        }
      </span>
      @if (hasPeriod) {
        <span class="period">/mois</span>
      }
    </span>
  </p>