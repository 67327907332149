<div class="options">
  @for (option of options; track option; let i = $index) {
    <div class="field">
      <a [attr.href]="urls && urls[i] ? urls[i] : null" (click)="haltDisabledEvents($event)" >
        <div class="control">
          <input type="radio" id="item{{i}}-{{UID}}" class="items-{{UID}}" name="options_group-{{UID}}" (click)="selectOption(option)" value="{{option}}"
            [attr.disabled]="!availableValues?.includes(option) ? '' : null" >
          <label for="item{{i}}-{{UID}}">{{option}}</label>
        </div>
      </a>
    </div>
  }
</div>