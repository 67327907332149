
import { Directive, Input, OnInit, Inject, SimpleChanges, OnChanges } from '@angular/core';
import animateScrollTo, { IOptions } from 'animated-scroll-to';
import { DOCUMENT } from '@angular/common';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[bytelScrollTo]',
    standalone: false
})
export class ScrollToDirective implements OnInit, OnChanges {

    /**
     * [Input DOM element used to scroll to]
     * @return {[type]} [description]
     */
    @Input()
    public domId: string;
    @Input()
    public offsetMargin = 0;
    @Input()
    public triggerInitialScroll = false; // Scroll on page load if the element to scroll to is in the dom
    @Input()
    public enableScroll = false;
    @Input()
    public targetFallback = '';
    @Input()
    public elementToScroll: HTMLElement;

    private _scrollOptions: Partial<IOptions> = {
        speed: 500,
        minDuration: 250,
        maxDuration: 3000,
        cancelOnUserAction: true
    };

    constructor(@Inject(DOCUMENT) private document: any) {}

    public ngOnInit(): void {
        this._scrollOptions.elementToScroll = this.elementToScroll || window;
        if (this.triggerInitialScroll) {
            this._scrollToTarget();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.enableScroll.currentValue) {
            this._scrollToTarget();
        }
    }

    /**
     *
     * Scroll to defined target
     * @private
     * @memberof ScrollToDirective
     */
    private _scrollToTarget(): void {

        const target: Element = this.document.defaultView.document.querySelector(`#${this.domId}`)
        || this.document.defaultView.document.querySelector(`.${this.domId}`);
        let offset = 0;

        if (target) {
            offset = (target as HTMLElement).offsetTop + this.offsetMargin;
        }

        if (this.targetFallback) {
            const targetFallback: Element = this.document.defaultView.document.querySelector(`#${this.targetFallback}`)
            || this.document.defaultView.document.querySelector(`.${this.targetFallback}`);

            if (!offset && targetFallback) {
                offset = (targetFallback as HTMLElement).offsetTop;
            }
        }
        animateScrollTo(offset, this._scrollOptions);
    }

}
