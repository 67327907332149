import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    standalone: false
})
export class OrderByPipe implements PipeTransform {
    public transform<T>(data: T[], ...args: string[]): T[] {
        const [
            property,
            order
        ] = args;
        data.sort((a, b) => (a[property] - b[property]));

        if (order) {
            data.sort(() => {
                if (order === 'asc') {
                    return 1;
                } else {
                    return -1;
                }
            });
        }
        return data;
    }

}
