import { Inject, Injectable } from '@angular/core';
import { SalesUser } from '@models/sales-user';
import { Oauth2Service, ConfigurationService } from '@common-modules';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { tap, map, catchError } from 'rxjs/operators';
import { StorageData } from '@repositories/storages/storage';
import { DOCUMENT } from '@angular/common';
import { PrismeLoggerService } from './prisme-logger.service';
import { PrismeLogType } from '../constants/prisme';

@Injectable({
    providedIn: 'root'
})
export class SalesUserService {

    public user: SalesUser;

    constructor(
        private oauth2Service: Oauth2Service,
        private configService: ConfigurationService,
        private prismeLogger: PrismeLoggerService,
        @Inject(DOCUMENT) protected document: Document,
    ) {
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | any {
        return this.oauth2Service.resolve(route).pipe(
            tap((result)=>{
                if (!result){ throw new Error('You are not LOGGED'); }
                this.user = SalesUser.fromOauth2Token(this.oauth2Service.jwtPayload,this.configService.data_refconf?.user_roles || {});
            }),
            map(()=>true),
            catchError(()=>of(false))
        );
    }

    public closeCall(): void{
        this.prismeLogger.sendDataToPrismePromise(
            PrismeLogType.CUSTOM_LOG,
            {
                message: 'Terminer Session'
            }
        ).finally(() => {
            StorageData.clear(sessionStorage);
            this.document.location.href = '';
        });

    }
}
