import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import { COFIDIS_METHODS, FUNDING_METHODS } from '@interfaces/payment.interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { FundingMethod } from '@models/payment-method';
import { QualificationModel } from '@models/qualification';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SalesApiHelper } from '../helper/sales-api.helper';
import { CustomerService } from '@services/customer/customer.service';
import { CUSTOMER_CATEGORY } from '@bytel/bytel-sales';

@Injectable({
    providedIn: 'root'
})
export class PaymentMethodsRepository {

    constructor(
        private readonly oauth2Ressource: Oauth2ResourcesService,
        private readonly customerService: CustomerService,
        private httpClient: HttpClient
    ) {}

    public getPaymentMethods(cartModel: MainCartModel, qualification: QualificationModel): Observable<{
        modesPaiement: string[];
    }> {

        if (cartModel.cartId) {
            return this.oauth2Ressource
                .ventes()
                .panier(cartModel.cartId)
                .modesPaiement()
                .useSalesApi()
                .get();
        } else {
            return this.oauth2Ressource
                .ventes()
                .panier()
                .modesPaiement()
                .useSalesApi()
                .post({
                    ...SalesApiHelper.GetCartGenericParams(cartModel),
                    typologieAppel: qualification?.calltype.toUpperCase(),
                    typeClient: this.customerService.isCustomerOrProspectPro() ? CUSTOMER_CATEGORY.PRO : CUSTOMER_CATEGORY.GP,
                });
        }
    }

    public getFundingMethodInfo(
        cofidisConfig: {url: string; partnerId: string},
        funding: string,
        paymentMethods: string[],
        amount: number
    ): Observable<FundingMethod>{
        const cofidisCode: number = COFIDIS_METHODS[funding];
        if (!cofidisCode) { // not cofidis method
            return of(new FundingMethod({
                type: funding,
                montant: amount,
                montantTotalDu: amount,
                idOption: 0,
                label: FUNDING_METHODS[funding].label,
                paymentMethods
            }));
        }
         
        return this.httpClient.jsonp(`${cofidisConfig.url}?callback=angular.callbacks._0&id=${cofidisConfig.partnerId}&montant=${amount}&option=${cofidisCode}`, 'callback')
            .pipe(
                map((cofidisInfo) =>
                    new FundingMethod({
                        type: funding,
                        label: FUNDING_METHODS[funding].label,
                        paymentMethods,
                        ...cofidisInfo
                    }))
            );
    }

}

