import { AfterViewInit, Directive, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output } from '@angular/core';
import autocomplete, { AutocompleteItem } from 'autocompleter';
import { Observable } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { finalize, tap } from 'rxjs/operators';

export interface ITlvAutocompleteItem<T = any> extends AutocompleteItem{
    data: T;
}

@Directive({
    selector: '[tlvAutocomplete]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteDirective),
            multi: true
        }
    ],
    standalone: false
})
export class AutocompleteDirective implements AfterViewInit, ControlValueAccessor{

    @Input() showOnFocus = false;
    @Input() minLength = 2;
    @Input() perfectMatch = false;
    @Input() autoSelect = true;
    @Input() simpleInput = false;
    @Input() getValues: (value: string) => Observable<ITlvAutocompleteItem[]>;
    @Output() onSelect = new EventEmitter<ITlvAutocompleteItem>();

    private onChange: (item: ITlvAutocompleteItem) => void;
    private onTouched: () => void;
    private val: ITlvAutocompleteItem = null;
    private _focusFetch = false;

    constructor(private el: ElementRef) { }

    public ngAfterViewInit(): void {
        autocomplete<ITlvAutocompleteItem>({
            minLength: this.minLength,
            debounceWaitMs: 500,
            input: this.el.nativeElement,
            fetch: (text, update) => {
                if (this.simpleInput && text) {
                    this._updateValue({label:text,data:text});
                } else if (this.val && text === this.val.label){
                    return;
                }
                this.getValues(text).pipe(
                    tap((data)=>{
                        if (!this._focusFetch && (this.autoSelect && data.length === 1)) {
                            this._updateValue(data[0]);
                        } else if (this.perfectMatch && data.find((v)=>v.label === text)) {
                            this._updateValue(data.find((v) => v.label === text),false);
                        } else if (!this.simpleInput){
                            this.val = null;
                            this.onChange(null);
                        }
                    }),
                    finalize(() => this._focusFetch = false)
                ).subscribe(update);

            },
            render: (item, currentValue) => this._highlightAndRender(item, currentValue),
            onSelect: this._updateValue.bind(this),
            showOnFocus: this.showOnFocus
        });
    }


    @HostListener('keydown.enter', ['$event'])
    onEvent(event: KeyboardEvent): void {
        event.preventDefault();
    }

    @HostListener('keydown.backspace', ['$event'])
    onEventBackspace(): void {
        if (!this.simpleInput){
            this.val = null;
            this.onChange(null);
        }
    }
    public registerOnChange(fn: (item: ITlvAutocompleteItem) => void): void { this.onChange = fn; }
    public registerOnTouched(fn: () => void): void { this.onTouched = fn; }
    public writeValue(value: ITlvAutocompleteItem): void {
        if (value){
            this.val = value;
            this.el.nativeElement.value = value.label;
        } else {
            this.val = null;
            this.el.nativeElement.value = '';
        }
    }

    public setDisabledState(isDisabled: boolean): void{
        this.el.nativeElement.disabled = isDisabled;
    }

    @HostListener('focus')
    private _onFocus(): void{
        this._focusFetch = !!this.el.nativeElement.value;
    }

    private _updateValue(value: ITlvAutocompleteItem,focusOut = true): void{
        this.el.nativeElement.value = value ? value.label : '';
        this.val = value;
        this.onChange(value);
        this.onTouched();
        if (focusOut && !this.simpleInput) {
            this.el.nativeElement.blur();
        }
    }

    private _highlightAndRender(item: ITlvAutocompleteItem, currentValue: string): HTMLDivElement {

        const div = document.createElement('div');
        const textInput = currentValue.trim();

        div.setAttribute('class', 'autocomplete-item');
        div.setAttribute('data-cy', 'autocomplete-item-selection');
        if (!textInput || textInput && !item.label.toLowerCase().includes(textInput.toLowerCase())) {
            div.innerHTML = item.label;
            return div;
        }

        const replacedValue = new RegExp(textInput, 'gi');
        div.innerHTML = item.label.replace(replacedValue, `<span class="autocomplete-highlight">${currentValue.toUpperCase()}</span>`);
        return div;
    }
}
