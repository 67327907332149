import { FaiAddressModel } from '@models/fai/fai-address';
import { FaiEligibilityModel } from '@models/fai/fai-eligibility.model';
import { FaiLogementModel } from '@models/fai/fai-logement.model';
import { FaiVerticalStructureModel } from '@models/fai/Fai-vertical-structure.model';
import { Type } from 'class-transformer';

import {  FAI_CART_TECHNO, FAI_CART_TYPE } from '../../constants/fai';

export class FaiCartModel{

    @Type(() => FaiLogementModel)
    public logements: FaiLogementModel[];
    @Type(() => FaiLogementModel)
    public selectedLogement: FaiLogementModel;
    @Type(() => FaiAddressModel)
    public searchAddress: FaiAddressModel;
    public zlin: boolean;
    public searchNumber: string;
    public option: {status?: boolean} = {};
    @Type(() => FaiEligibilityModel)
    public eligibility: FaiEligibilityModel;
    @Type(() => FaiVerticalStructureModel)
    public verticalStructures?: FaiVerticalStructureModel[];
    @Type(() => FaiVerticalStructureModel)
    public selectedVerticalStructure: FaiVerticalStructureModel;
    public selectedLogin: string;
    public isCustomLogin = false;
    public selectedVoip: string;

    public updated = false;

    constructor(public type: FAI_CART_TYPE,
                public techno: FAI_CART_TECHNO,
                public id: string) {
    }

    public setLogements(logements: FaiLogementModel[]): void{
        this.logements = logements.map((logement)=> new FaiLogementModel(logement));
    }

    public setSearch(search: FaiAddressModel|string): void{
        if (search instanceof FaiAddressModel){
            this.searchAddress = search;
            this.searchNumber = null;
        } else {
            this.searchAddress = null;
            this.searchNumber = search;
        }
    }

    public setStatus(status: boolean): void{
        this.option.status = status;
    }

    public setEligibility(data: FaiEligibilityModel): void{
        this.eligibility = data;
    }

}
