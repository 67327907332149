import { Pipe, PipeTransform } from '@angular/core';

enum SimTypesLabels {
    sim = 'SIM',
    esim = 'eSIM',
    hybride = 'SIM & eSIM'
};

@Pipe({
    name: 'simTypeLabel',
    standalone: false
})
export class SimTypeLabelPipe implements PipeTransform {

    public transform(type: string): string {
        return SimTypesLabels[type] || SimTypesLabels.sim;
    }

}
