import { USER_TITLES } from '../../constants/customer';
import {  CONTRACT_STATUS } from '@interfaces/contract.interface';
import { BillingAccountModel } from '@models/customer/billing-account.model';
import { Type } from 'class-transformer';
import { SignedContractModel } from '@models/customer/signedContract.model';

export class CustomerModel {
    public birthDepartment: string;
    @Type(() => BillingAccountModel)
    public comptesFacturations: BillingAccountModel[] = [];
    @Type(()=>SignedContractModel)
    public signedContracts: SignedContractModel[];
    public idPerson: string;
    public isClient: boolean;
    public idIdentity: string;
    public isUpdatable = false;
    @Type(() => Date)
    public birthDate: Date;
    public firstname: string;
    public lastname: string;
    public gender: USER_TITLES;
    public isVip = false;
    public rna: string;

    public isUpdated?: boolean;
    public iban?: string;

    constructor(data: Partial<CustomerModel>){
        Object.assign(this,data);
        if (data) {
            this.comptesFacturations = data.comptesFacturations || [];
            this.signedContracts = data.signedContracts || [];
            this.isClient = this.signedContracts.some((contract) =>
                contract.status === CONTRACT_STATUS.ACTIVE
            );
        }
    }

}
