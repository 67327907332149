import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    DELIVERY = 'DELIVERY',
    CART = 'CART',
    BILLING_ADDRESS = 'BILLING_ADDRESS',
    SHIPPING_ADDRESS = 'SHIPPING_ADDRESS',
    PORTABILITY = 'PORTABILITY',
    SHIPPING_STORE = 'SHIPPING_STORE',
    SHIPPING_APPOINTMENT = 'SHIPPING_APPOINTMENT',
    APPOINTMENT = 'APPOINTMENT',
    MOBILE_RECOVERY = 'MOBILE_RECOVERY',
    QUOTE_MOBILE_RECOVERY = 'QUOTE_MOBILE_RECOVERY'
}

export class CheckoutStorage extends StorageData{

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix = 'checkout_';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
