import { Model } from './model';

export class QualificationModel extends Model {

    public calltype: string;
    public campaign: string;
    public isProspectPro: boolean;

    constructor(data: Partial<QualificationModel>){
        super(data);
    }

    public hasData(): boolean {
        return !!this.calltype || !!this.campaign || !!this.isProspectPro;
    }

}

