import { BasicObject } from '@common-modules';


export interface ApiCatalogCategories {
    categories: Record<string, ApiCatalogCategory>;
}

export interface ApiCatalogCategory {
    products: string[];
    code: string;
    description: string;
    id: string;
    meta_description: string;
    meta_keywords: string;
    meta_title: string;
    name: string;
    _links: BasicObject;
}

export enum ALLOWED_CATEGORIES {
    TOP_TELESALES = 'top_telesales',
    PHONE_TELESALES = 'phone_telesales',
    PLAN_PREMIUM_TELESALES = 'plan_premium_telesales',
    PLAN_SOWO_TELESALES = 'plan_sowo_telesales',
    FAIM_TELESALES = 'faim_telesales',
    FAIM_PREMIUM_TELESALES = 'faim_premium_telesales',
    DEVICE_FAIM_TELESALES = 'device_faim_telesales',
    PROMPTO_TELESALES = 'prompto_telesales',
    SIMOFAI_TELESALES = 'FAI-SIMO-TELESALES'
}
