import { Permissions } from '@interfaces/sales-user';
import { Model } from './model';

export class SalesUser extends Model {

    public code_site: string;
    public login: string;
    public id: string;
    public firstname: string;
    public lastname: string;
    public email: string;
    public username: string;
    public libelle_unite: string;
    public hash: string;
    public civility?: string;
    public birthday?: string;
    public department?: string;
    public publicphone?: string;
    public primaryRole: string;
    public user_type: string;
    public permissions: Permissions;

    constructor(data: Partial<SalesUser>){
        super(data);
    }

    public static fromOauth2Token(data: any, configPermission: any): SalesUser {
        return new SalesUser({
            hash: data?.nonce,
            id: data?.matricule,
            ...(data?.site ? { code_site: data?.site.replace('_', '') } : {}),
            firstname: data?.given_name,
            lastname: data?.family_name,
            username: data?.name,
            login: data?.login,
            user_type: data?.user_type,
            ...this.extractPermissions(data.roles,configPermission)
        });
    }

    public static extractPermissions(
        roles: string[],
        configPermission: Record<string, Permissions>
    ): { primaryRole: string;permissions: Permissions } {
        const configuredRules: string[] = Object.keys(configPermission);
        const userRole: string = roles.find(role => configuredRules.includes(role));
        return {
            primaryRole: userRole,
            permissions: configPermission[userRole]
        };
    }
}
