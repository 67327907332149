<tlv-category-tabs (onSelectedCategoryChange)="onCategoryChange($any($event))" />
<tlv-filters-form
  [productType]="productType"
  (onFormChange)="onFiltersFormChange($event)"
  [productsCount]="totalProductCount"
  />
@if (!hasScoringErrors) {
  <div class="is-divider has-background-light is-marginless"></div>
}
<tlv-partial-scoring (onScoringError)="hasScoringErrors = $event" />
@if (!isLoadingProducts && hasProductLoadFailed) {
  <div class="columns is-centered is-vcentered is-multiline">
    <div class="column is-12"></div>
    <div class="column is-narrow">
      <div class="alert has-body is-error">
        <span class="icon"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
        <div class="body">
          <div class="title is-level-3 is-loaded">Erreur catalogue</div>
          <p class="text is-loaded">Une erreur est survenue, merci de recharger les produits</p>
          <div class="buttons is-centered">
            <button class="button is-loaded is-tertiary enabled" (click)="reloadProducts()">Recharger</button>
          </div>
        </div>
      </div>
    </div>
  </div>
} @else {
  @if (productType === PRODUCT_TYPES.PLAN) {
    <tlv-plans-list [productType]="productType" [category]="currentCategory"/>
  } @else {
    <tlv-devices-list
      [isSelectedPlanPremium]="isSortedByPremiumPlan"
      [isLoading]="isLoadingProducts"
      [isLoadingMoreProducts]="isLoadingMoreProducts"
      [products]="devices"
      [productType]="productType"
      (onPageScroll)="updateDevicesOnPageScroll()" />
  }
}
