import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of as observableOf } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';

@Injectable({
    providedIn: 'root'
})
export class BtlCmsService {

    protected cache: Record<string, Observable<any>> = {};

    constructor(
        private http: HttpClient,
        private configService: ConfigurationService) {
    }

    public getContent(blockId: string, battlefront: boolean, addIndexHtmlToUrl: boolean): Observable<any> {
        if (!this.cache[blockId]) {
            const block$ = this._getBlock(blockId, battlefront, addIndexHtmlToUrl);
            block$.pipe(tap((data) => { this.cache[blockId] = observableOf(data); }));
            return block$;
        }
        return this.cache[blockId];
    }

    private _getUri(blockId: string, battlefront: boolean, addIndexHtmlToUrl: boolean): string {
        if (battlefront) {
            return `${this.configService.data_refconf.cms.battlefront}/${blockId}${addIndexHtmlToUrl ? '/index.html' : ''}?trilogy=-1`;
        } else {
            return `${this.configService.data_refconf.cms.bta}/${blockId}`;
        }
    }

    private _getBlock(blockId: string, battlefront: boolean, addIndexHtmlToUrl: boolean): Observable<any> {
        const uri = this._getUri(blockId, battlefront, addIndexHtmlToUrl);
        return this.http.get(uri, { responseType: 'text' }).pipe(
            map(response => ({ html: response }))
        );
    }
}
