import { EventEmitter, Component, Input, Output } from '@angular/core';

@Component({
    selector: 'tlv-hero-title',
    templateUrl: './hero-title.component.html',
    styleUrls: ['./hero-title.component.scss'],
    standalone: false
})
export class HeroTitleComponent {

    @Input() public iconName: string;
    @Input() public title: string;
    @Input() public isClosable = false;
    @Output() public onClose = new EventEmitter<any>();

    public onClickAction(): void {
        this.onClose.emit();
    }
}
