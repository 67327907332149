import { Injectable } from '@angular/core';
import { QualificationModel } from '@models/qualification';
import { CustomerStorage } from '@repositories/storages/customer.storage';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable, ReplaySubject } from 'rxjs';

enum STATUS {
    COMPLETED = 'completed',
    ONGOING = 'ongoing'
}
@Injectable({
    providedIn: 'root'
})
export class QualificationService {

    public readonly _$statusSubject = new ReplaySubject<STATUS>();

    public readonly STATUS = STATUS;
    public qualification: QualificationModel;
    public $statusObs: Observable<STATUS> = this._$statusSubject.asObservable();
    private _status: STATUS;

    constructor() {
        this._restoreFromStorage();
        this._status = this.qualification ? STATUS.COMPLETED : STATUS.ONGOING;
        this._$statusSubject.next(this._status);
    }

    public update(data: QualificationModel): void {
        this.qualification = data;
        this._status = data && Object.keys(data).length ? STATUS.COMPLETED : STATUS.ONGOING;
        this._$statusSubject.next(this._status);
        this.save();
    }

    public isDadCampaign(): boolean {
        return this.qualification.campaign === 'DAD' ;
    }

    public clear(): void {
        this.qualification = null;
        CustomerStorage.removeItem(CustomerStorage.KEYS.QUALIFICATION);
    }

    public save(): void {
        CustomerStorage.setItem(CustomerStorage.KEYS.QUALIFICATION, classToPlain(this.qualification));
    }

    private _restoreFromStorage(): void {
        const qualifData = CustomerStorage.getItem(CustomerStorage.KEYS.QUALIFICATION);
        if (qualifData && Object.keys(qualifData).length) {
            this.update(plainToClass(QualificationModel, qualifData));
        }
    }
}
