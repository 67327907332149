import {Injectable} from '@angular/core';
import { ConfigurationService, Oauth2ResourcesService, Oauth2Service} from '@common-modules';
import {Fai, PRICE_TYPES, QUOTE_CONTEXTS} from '@bytel/bytel-sales';
import {
    ACTEUR_CREATEUR_TYPES,
    ElementsCommande,
    FichesRetour,
    IAbandonedCart,
    ICmdCommercial,
    ICmdsCommercials,
    ModeLivraison,
    OffresAchetee,
    ProduitsALivrer
} from '@interfaces/api/commande.interface';
import {OrderSummary} from '@interfaces/checkout/cart.interface';
import {AbandonedCartModel} from '@models/abandoned-cart.model';
import {AddressModel} from '@models/cart/address.model';
import {AppointmentModel} from '@models/cart/appointment.model';
import {DeliveryInfoModel} from '@models/cart/delivery-info.model';
import {PortabilityModel} from '@models/cart/portability.model';
import {FaiAddressModel} from '@models/fai/fai-address';
import {FaiLogementModel} from '@models/fai/fai-logement.model';
import {FaiOfferModel} from '@models/fai/fai-offer.model';
import {FaiPtoModel} from '@models/fai/fai-pto.model';
import {OrderCartInfoModel} from '@models/order/order-cart-info.model';
import {OrderDeliveryInfoModel} from '@models/order/order-delivery.model';
import {OrderFaiInfoModel} from '@models/order/order-fai-info.model';
import {OrderPaymentInfoModel} from '@models/order/order-payment-info.model';
import {OrderModel} from '@models/order/order.model';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {EQUIPMENT_FILTER_LABELS, FAI_CART_TYPE} from '../constants/fai';
import {CHANNEL, ORDER_ELEMENT_TYPE, ORDER_STATUS} from '../constants/order';
import bind from '../helper/decorators/bind';
import {ProductRepository} from './product.repository';
import {ObjectOf} from '../helper/types';
import {OrderDeliveryQuoteInfoModel} from '@models/order/order-delivery-quote-info.model';
import {QualificationService} from '@services/qualification.service';
import {ElementActe, IPanierRetoursRepriseParcours} from '@interfaces/api/order-recovery.interface';
import {OpportunityService} from '@services/opportunity.service';
import {PAYMENT_METHODS} from '@interfaces/payment.interface';
@Injectable({
    providedIn: 'root'
})
export class OrderRepository {

    constructor(
        protected oauth2: Oauth2Service,
        private readonly oauth2Ressource: Oauth2ResourcesService,
        private readonly productRepository: ProductRepository,
        private configService: ConfigurationService,
        private qualificationService: QualificationService,
        private opportunityService: OpportunityService
    ) { }

    public getAbandonedCart(noPersonne: string): Observable<AbandonedCartModel[]> {
        if (!noPersonne) {
            return of([]);
        }
        const withDetail = 1;
        return this.oauth2Ressource
            .useSalesApi()
            .ventes()
            .panier()
            .orcom()
            .setParams({ noPersonne, withDetail })
            .get()
            .pipe(
                map((data: IAbandonedCart[]) =>
                    data.map((item: IAbandonedCart) => new AbandonedCartModel({
                        offer: item.offresAchetees,
                        canal: item.canal,
                        acteurCreateur: item?.acteurCreateur?.type,
                        status: ORDER_STATUS.NOT_COMPLETE,
                        id: item.idPanier,
                        orderCreation: new Date(item.dateCreation),
                    }))
                ));
    }

    public getCommercialOrdersOfPersonne(personId: string): Observable<OrderModel[]> {
        return this.oauth2Ressource
            .useSalesApi()
            .personnes(personId)
            .commandesCommerciales()
            .get()
            .pipe(
                map((data: ICmdsCommercials) =>
                    data.items.map((item) => this._generateOrderModel(item))
                )
            );
    }

    public getCommercialOrder(orderId: string): Observable<OrderModel> {

        return this.oauth2Ressource
            .useSalesApi()
            .commandesCommerciales(orderId)
            .get()
            .pipe(
                map(this._generateOrderModel),
                catchError(() => of(null))
            );
    }

    public getPanierRetoursRepriseParcours(orderId: string): Observable<OrderModel> {

        return this.oauth2Ressource
            .useSalesApi()
            .commandesCommerciales(orderId)
            .panierRetoursRepriseParcours()
            .get()
            .pipe(
                map((data: IPanierRetoursRepriseParcours) => this._generateOrderModel(this._convertToICmdCommercial(data))),
                catchError(() => of(null))
            );
    }

    public getOrderData(orderId: number): Observable<OrderModel[]> {
        if (!this.configService.data_refconf?.checkout?.orcom_paniermixte_active) {
            return this.oauth2Ressource
                .useSalesApi()
                .commandesCommerciales()
                .setParams({ id: orderId })
                .get()
                .pipe(
                    map((data) => data.items.map((item) => this._generateOrderModel(item))),
                    catchError(() => of(null))
                );
        }
        return this.oauth2Ressource
            .useSalesApi()
            .commandesCommerciales(orderId.toString())
            .get()
            .pipe(
                map(this._generateOrderModel),
                map((data) => [data]),
                catchError(() => of(null))
            );
    }

    public notityHybridPaymentConversion(orderId: string): Observable<any> {
        return this.oauth2Ressource
            .useSalesApi()
            .commandesCommerciales(orderId)
            .notifierPaiementHybride()
            .post({
                id: orderId
            });
    }

    public getOrderSummary(cartId: number, paymentType: string, isFmsPaymentDiffered?: boolean): Observable<OrderSummary> {
        if (this.configService.data_refconf?.checkout?.closing_active !== true) {
            const empty_summary: OrderSummary = {
                description: '',
                message: ''
            };
            return of(empty_summary);
        }
        return this.oauth2Ressource.useSalesApi().orderSummary(cartId.toString(), paymentType, isFmsPaymentDiffered).get();
    }

    private _convertToICmdCommercial(data: IPanierRetoursRepriseParcours): ICmdCommercial {

        const cmdCommercial: ICmdCommercial = ({} as any) as ICmdCommercial;
        const elemCmd: ElementsCommande[] = [];
        const ficheRetour: FichesRetour[] = [];


        cmdCommercial.acteurCreateur = data.panierRetours.acteurCreateur;
        cmdCommercial.acteurCreateur.noPersonne = data.donneesAcheteur.noPersonne;

        cmdCommercial.offresAchetees = [];

        data.panierRetours.actesAV[0].elementsRetournes.forEach(
            (elem: ElementActe) => elemCmd.push(elem.elementCommande)
        );
        cmdCommercial.offresAchetees[0] = {
            idOpportunite: '',
            idParcoursVente: '', libelle: '', type: '', elementsCommandes:elemCmd
        };

        const offreLivraison: ElementActe = data.panierRetours.actesAV[0].elementsRetournes.find(
            (ElementRetourne: ElementActe) => ElementRetourne.elementCommande.type === 'OFFRE_LIVRAISON'
        );

        if (offreLivraison) {
            cmdCommercial.modeLivraison = {
                adresseLivraison: offreLivraison.elementCommande.modeLivraison.adresseLivraison,
                dateLivraisonEstimee: '',
                dateLivraisonEstimeeAuPlusTard: '',
                reseauLivraison: undefined,
                type: ''
            };
        }


        if (data.panierRetours.statut === 'MODIFICATION_EN_COURS' && data.panierRetours.motifCreation === 'MODIFICATION_PARCOURS') {
            ficheRetour[0] = {
                dateCreation: '',
                elementsCommandes: [],
                id: '',
                remplacementParcours: undefined,
                statut: 'CREE', type:'MODIFICATION_AVANT_EXPEDITION'};
        }
        cmdCommercial.fichesRetour = ficheRetour;
        cmdCommercial.adresseFacturation = data.donneesAcheteur.adresseFacturation;
        cmdCommercial.statut = data.panierRetours.statut;
        cmdCommercial.id = data.panierRetours.idCommandeOrigine;
        cmdCommercial.email = data.donneesAcheteur.contact.email;
        cmdCommercial.noTelephone = data.donneesAcheteur.contact.noTelephone;
        cmdCommercial.idPanierRetours = data.panierRetours.idPanierRetours;
        cmdCommercial.version = data.panierRetours.version;

        if (data.donneesAcheteur.iban) {
            cmdCommercial.comptesPayeursCrees = [];
            cmdCommercial.comptesPayeursCrees.push(
                {idParcoursVente: '', compteBancaire:{iban: data.donneesAcheteur.iban}}
            );
        }

        return cmdCommercial;

    }

    private _getCanal(type: ACTEUR_CREATEUR_TYPES): CHANNEL {
        const actorTypes: ObjectOf<CHANNEL> = {
            [ACTEUR_CREATEUR_TYPES.CDV]: CHANNEL.RCBT,
            [ACTEUR_CREATEUR_TYPES.CDC]: CHANNEL.TLV,
            [ACTEUR_CREATEUR_TYPES.TLV]: CHANNEL.TLV,
            [ACTEUR_CREATEUR_TYPES.PERSONNE]: CHANNEL.WEB
        };
        return actorTypes[type] ?? CHANNEL.OTHER;
    }

    @bind
    private _generateOrderModel(data: ICmdCommercial): OrderModel {

        return new OrderModel({
            idPanierRetours: data.idPanierRetours,
            version: data.version,
            reusable: this._isOrderReusable(data),
            offer: this._removeExpiredFaiOffers(data),
            canal: this._getCanal(data.acteurCreateur.type),
            acteurCreateur: data.acteurCreateur.type,
            status: ORDER_STATUS[data.statut] ?? data.statut,
            statusChangeReason: data.causeChangementStatut,
            id: data.id,
            fai: new OrderFaiInfoModel({
                address: this._getFaiAddress(data),
                logement: this._getFaiLogement(data),
                offer: this._getFaiOffer(data),
                pto: this._getPto(data),
                type: FAI_CART_TYPE.ADDRESS,
                isCDL: !!this._getFaiOrderElement(data)?.caracteristiquesLigne?.constructionDeLigne
            }),
            cart: this._getCartItems(data),
            orderCreation: new Date(data.dateEnregistrement),
            customerEmail: data.email,
            mainPhoneNumber: data.noTelephone,
            initialPaymentData: {
                ...(data.paiementInitial?._actions?.payerCommande ? { paymentRetrival: true } : {}),
                ...(data.paiementInitial?._actions?.notifierPaiementHybride ? { transformToHybrid: true } : {}),
            },
            personId: data.acheteur?.noPersonne || data.acteurCreateur?.noPersonne
        });
    }

    private _removeExpiredFaiOffers(data: ICmdCommercial): OffresAchetee[] {
        const hasStatusToFilter = !!data?.offresAchetees.find(o=>o.codeCauseChangementActivite === 'ACQUISITION_FIXE_A_TRAITER');
        if (!hasStatusToFilter) {
            return data.offresAchetees.every(o=>o.statut === 'ANNULE')
                ? data.offresAchetees : data.offresAchetees.filter(o=>o.statut !== 'ANNULE');
        } else {
            return data.offresAchetees.filter(o=>o.codeCauseChangementActivite !== 'ACQUISITION_FIXE_ANNULEE');
        }
    }

    private _isOrderReusable(data: ICmdCommercial): boolean {
        if (!data.fichesRetour.length) {
            return false;
        }

        const fichesRetour: FichesRetour = data.fichesRetour.reduce(
            (prev, current) => (prev.dateCreation > current.dateCreation) ? prev : current
        );

        return (fichesRetour.statut === 'CREE' && fichesRetour.type === 'MODIFICATION_AVANT_EXPEDITION');
    }


    private _getPrincipalOffer(data: ICmdCommercial, type: ORDER_ELEMENT_TYPE = ORDER_ELEMENT_TYPE.OFFRE_PRINCIPALE): ElementsCommande {
        for (const offreAchetee of data.offresAchetees) {
            const elementCommande: ElementsCommande = offreAchetee.elementsCommandes
                .find((eltCommande: ElementsCommande) => eltCommande.type === type);
            if (elementCommande) { return elementCommande; }
        }
    }

    private _getFaiOrderElement(data: ICmdCommercial, type: ORDER_ELEMENT_TYPE = ORDER_ELEMENT_TYPE.OFFRE_PRINCIPALE): ElementsCommande {
        for (const offreAchetee of data.offresAchetees) {
            const foundElementCommandeFAI = offreAchetee.elementsCommandes.some(
                (eltCommande: ElementsCommande) => !!eltCommande.adresseInstallation
            );
            if (foundElementCommandeFAI) {
                return offreAchetee.elementsCommandes.find((eltCommande: ElementsCommande) => eltCommande.type === type);
            }
        }
        return null;
    }


    private _getOffersByType(data: ICmdCommercial, type: ORDER_ELEMENT_TYPE = ORDER_ELEMENT_TYPE.OFFRE_PRINCIPALE): ElementsCommande[] {
        return data.offresAchetees.filter((eltCommande: ElementsCommande) => eltCommande.type === type);
    }

    private _getFaiLogement(data: ICmdCommercial): FaiLogementModel {
        const elementCommande = this._getFaiOrderElement(data);
        if (elementCommande?.adresseInstallation) {
            return new FaiLogementModel({
                batiment: elementCommande.adresseInstallation?.batiment,
                escalier: elementCommande.adresseInstallation?.escalier,
                etage: elementCommande.adresseInstallation?.etage?.padStart(2, '0'),
                ndi: elementCommande?.caracteristiquesLigne?.ndi,
                nomTitulaire: null,
                porte: elementCommande.adresseInstallation?.porte,
                logo: elementCommande.adresseInstallation?.logoFT,
                ndStatut: elementCommande?.caracteristiquesLigne?.statutNDI,
                residence: elementCommande.adresseInstallation?.residence,
                numeroVoie: elementCommande.adresseInstallation?.numero,
                codePostal: elementCommande.adresseInstallation.codePostal,
                commune: elementCommande.adresseInstallation.ville,
                voie: elementCommande.adresseInstallation.rue,
                codeInsee: elementCommande.adresseInstallation.codeInsee,
                codeVoie: elementCommande.adresseInstallation.codeRivoli
            });
        }
        return null;
    }

    private _getPto(data: ICmdCommercial): FaiPtoModel {
        const elementCommande = this._getFaiOrderElement(data);
        if (elementCommande?.adresseInstallation) {
            return new FaiPtoModel({
                batiment: elementCommande.adresseInstallation?.batiment,
                escalier: elementCommande.adresseInstallation?.escalier,
                etage: elementCommande.adresseInstallation?.etage,
                presencePto: elementCommande.caracteristiquesLigne?.priseFtthExistant,
                currentPto: elementCommande.caracteristiquesLigne?.idPriseFFTH
            });
        }
        return null;
    }

    private _getFaiAddress(data: ICmdCommercial): FaiAddressModel {
        const elementCommande = this._getFaiOrderElement(data);
        if (elementCommande?.adresseInstallation) {
            return new FaiAddressModel({
                postal: elementCommande.adresseInstallation.codePostal,
                city: { libelle: elementCommande.adresseInstallation.ville, code: elementCommande.adresseInstallation.codeInsee },
                street: { libelle: elementCommande.adresseInstallation.rue, code: elementCommande.adresseInstallation.codeRivoli },
                street_number: elementCommande.adresseInstallation.numero
            });
        }
        return null;
    }

    private _getFaiOffer(data: ICmdCommercial): FaiOfferModel {
        const elementCommande = this._getFaiOrderElement(data);

        if (elementCommande?.adresseInstallation) {
            const mainOfferPrices: { rent: number; offer: number } = {
                offer: elementCommande.tarifsCommerciaux[0]?.montantTTC,
                rent: elementCommande.tarifsCommerciaux[1]?.montantTTC
            };

            const elementCommandeFMS: ElementsCommande = this._getFaiOrderElement(data, ORDER_ELEMENT_TYPE.FRAIS);
            const elementCommandeDiscount: ElementsCommande = this._getFaiOrderElement(data, ORDER_ELEMENT_TYPE.REMISE_ABONNEMENT);
            const discountPrice: number = elementCommandeDiscount ? elementCommandeDiscount.tarifsCommerciaux[0].montantTTC : 0;

            const faiOfferModel: FaiOfferModel = new FaiOfferModel({
                name: elementCommande.libelleCommercial,
                price: mainOfferPrices.offer + mainOfferPrices.rent,
                equipmentName: null,
                fmsPrice: elementCommandeFMS.tarifsCommerciaux[0].montantTTC,
                obligation: data.offresAchetees.find(offer => !!offer?.dureeEngagement)?.dureeEngagement,
                discount: discountPrice ? discountPrice * -1 : 0,
                id: elementCommande.noOffre,
                priceWithDiscount: discountPrice ? +(mainOfferPrices.offer + mainOfferPrices.rent + discountPrice).toFixed(2) : 0
            });

            this.productRepository.getProductByGencode(faiOfferModel.id).subscribe(faiProduct => {
                faiOfferModel.equipmentName = faiProduct ? EQUIPMENT_FILTER_LABELS[(faiProduct as Fai).data.equipment] : null;
            });

            return faiOfferModel;
        }
    }

    // this method seems not to be compatible with multi quote
    private _getCartItems(data: ICmdCommercial): OrderCartInfoModel {
        const items = {
            id: data.idPanier,
            accessories: this._getAccessoriesFromOrder(data),
            options: this._getOptionsFromOrder(data),
            portability: this._getOfferPortabilityInfos(data),
            appointment: this._getOfferAppointment(data),
            delivery: this._getOfferDeliveryInfos(data),
            payment: this._getOfferPayment(data)
        };
        return new OrderCartInfoModel(items);
    }

    private _getAccessoriesFromOrder(data: ICmdCommercial): { name: string; gencode: string }[] {
        const accessories = [];
        for (const offreAchetee of data.offresAchetees) {
            offreAchetee.elementsCommandes
                .forEach((eltCommande: ElementsCommande) => {
                    if (eltCommande?.typeProduit === 'ACCESSOIRE' && eltCommande?.statut !== ORDER_STATUS.CANCELED) {
                        accessories.push({gencode: eltCommande.gencode, name: eltCommande.libelleCommercial});
                    }
                });
        }
        return accessories;
    }

    private _getOptionsFromOrder(data: ICmdCommercial): { name: string; gencode: string }[] {
        const options = [];
        for (const offreAchetee of data.offresAchetees) {
            offreAchetee.elementsCommandes
                .forEach((eltCommande: ElementsCommande) => {
                    if (['OFFRE_OPTION', 'OFFRE_PARTENAIRE'].includes(eltCommande?.type) && eltCommande?.statut !== ORDER_STATUS.CANCELED) {
                        options.push({gencode: eltCommande.noOffre, name: eltCommande.libelleCommercial});
                    }
                });
        }
        return options;
    }

    private _getOfferDeliveryInfos(data: ICmdCommercial): OrderDeliveryInfoModel {
        if (!data.adresseFacturation) {
            return null;
        }

        const {mobile, fai} = this._getMultiQuoteDeliveryInfo(data);
        const defaultDeliveryInfos = this._getPrincipalOffer(data, ORDER_ELEMENT_TYPE.OFFRE_LIVRAISON); // Multi Quote impact ?

        return {
            billing: new AddressModel({
                postalCode: data.adresseFacturation.codePostal,
                city: data.adresseFacturation.ville,
                street: data.adresseFacturation.rue,
                streetNumber: data.adresseFacturation.numero
            }),
            shipping: data.modeLivraison ? new AddressModel({
                postalCode: data.modeLivraison.adresseLivraison.codePostal,
                city: data.modeLivraison.adresseLivraison.ville,
                street: data.modeLivraison.adresseLivraison.rue,
                streetNumber: data.modeLivraison.adresseLivraison.numero
            }) : null,
            deliveryInfos: new DeliveryInfoModel({
                estimatedDeliveryDate: new Date(
                    data.modeLivraison ? data.modeLivraison.dateLivraisonEstimee : defaultDeliveryInfos?.modeLivraison?.dateLivraisonEstimee
                ),
                lateEstimatedDeliveryDate: new Date(
                    data.modeLivraison ? data.modeLivraison.dateLivraisonEstimeeAuPlusTard :
                        defaultDeliveryInfos?.modeLivraison?.dateLivraisonEstimeeAuPlusTard
                ),
                price: +defaultDeliveryInfos?.tarifsCommerciaux[0]?.montantTTC,
                libelle: data.modeLivraison?.type || defaultDeliveryInfos?.typeLivraison,
                gencode: data.modeLivraison?.type || defaultDeliveryInfos?.typeLivraison
            }),
            store: data?.modeLivraison?.pointRelais ? {
                id: data.modeLivraison.pointRelais.codeIdentification,
                name: data.modeLivraison.pointRelais.libelle,
                coordinates: null,
                icons: null,
                deliveryType: data.modeLivraison.type,
                street: data.modeLivraison.adresseLivraison.rue,
                street_number: +data.modeLivraison.adresseLivraison.numero,
                city: data.modeLivraison.adresseLivraison.ville,
                postalCode: +data.modeLivraison.adresseLivraison.codePostal,
                country: data.modeLivraison.adresseLivraison.pays,
                hours: null,
                rvc: data.modeLivraison?.pointRelais?.codeSecteurLivraison ?? null,
                reseauLivraison: data.modeLivraison.reseauLivraison?.nomCommercial ?? null,
            } : null,
            ...(mobile ? {mobile} : {}),
            ...(fai ? {fai} : {})
        };
    }

    private _getMultiQuoteDeliveryInfo(data: ICmdCommercial): { mobile: OrderDeliveryQuoteInfoModel; fai: OrderDeliveryQuoteInfoModel } {
        const deliveries: OrderDeliveryQuoteInfoModel[] = this._getOffersByType({ ...data }, ORDER_ELEMENT_TYPE.LIVRAISON)
            .map((offer: OffresAchetee) => offer.elementsCommandes)
            .flat(10) // 3 being the possible deepest level
            .filter((o: ElementsCommande) => o.type === 'OFFRE_LIVRAISON' && o?.modeLivraison)
            .map((delivery: ElementsCommande) => this._generateDeliveryQuoteModel(
                delivery.modeLivraison,
                delivery?.produitsALivrer ? delivery.produitsALivrer[0] : null,
                data)
            );
        return {
            mobile: deliveries.find(del => del.equipment === 'Mobile'),
            fai: deliveries.find(del => del.equipment === 'Bbox')
        };
    }

    @bind
     
    private _generateDeliveryQuoteModel(delivery: ModeLivraison, produitsALivrer: ProduitsALivrer, cmdCommercial: ICmdCommercial): OrderDeliveryQuoteInfoModel {
        return new OrderDeliveryQuoteInfoModel({
            estimatedDeliveryDate: new Date(delivery.dateLivraisonEstimee),
            lateEstimatedDeliveryDate: new Date(delivery.dateLivraisonEstimeeAuPlusTard),
            deliveryAddress: new AddressModel({
                postalCode: delivery?.adresseLivraison?.codePostal,
                city: delivery?.adresseLivraison?.ville,
                street: delivery?.adresseLivraison?.rue,
                streetNumber: delivery?.adresseLivraison?.numero
            }),
            type: delivery.type,
            equipment: this._getEquipmentType(produitsALivrer?.idElementCommandePanier, cmdCommercial)
        });
    }

    private _getEquipmentType(idElementCommandePanier: string, cmdCommercial: ICmdCommercial): string {
        if (!idElementCommandePanier){
            return;
        }
        const elmntsCommande: ElementsCommande = cmdCommercial.offresAchetees.find(element =>
            element.elementsCommandes.find(elmntCommandes =>
                elmntCommandes.idElementCommandePanier === idElementCommandePanier
            )
        );
        return elmntsCommande.type === 'ACQUISITION_FIXE' ? 'Bbox' : 'Mobile';
    }

    private _getOfferPortabilityInfos(data: ICmdCommercial): PortabilityModel {
        const elementCommande = this._getFaiOrderElement(data);
        if (!elementCommande?.adresseInstallation || (
            !elementCommande.demandePortabiliteFixe && !elementCommande.noVOIP
        )) { return; }

        if (elementCommande.demandePortabiliteFixe) {
            return new PortabilityModel({
                phoneNumber: elementCommande.demandePortabiliteFixe.numeroAPorter,
                rioCode: elementCommande.demandePortabiliteFixe.rio,
                login: elementCommande?.identifiantAcces?.login,
                lineType: QUOTE_CONTEXTS.ACQUISITIONFIX
            });
        } else {
            return new PortabilityModel({
                phoneNumber: elementCommande.noVOIP.numero,
                login: elementCommande?.identifiantAcces?.login,
                lineType: QUOTE_CONTEXTS.ACQUISITIONFIX
            });
        }
    }

    private _getOfferAppointment(data: ICmdCommercial): AppointmentModel {
        const elementCommande = this._getFaiOrderElement(data);

        if (elementCommande?.creneauRdvDemande) {
            return new AppointmentModel({
                start: new Date(elementCommande.creneauRdvDemande.debutCreneau),
                end: new Date(elementCommande.creneauRdvDemande.finCreneau),
                contactNumber: elementCommande.creneauRdvDemande.numeroContact,
                providerId: elementCommande.creneauRdvDemande.idPrestataire,
                id: elementCommande.creneauRdvDemande.idCreneau,
            });
        }
    }

    private _getOfferPayment(data: ICmdCommercial): OrderPaymentInfoModel {
        const elementCommande = this._getPrincipalOffer(data, ORDER_ELEMENT_TYPE.FRAIS); // Multi Quote impact ?

        if (!data.paiementInitial) {
            return new OrderPaymentInfoModel({
                iban: data?.comptesPayeursCrees ? data.comptesPayeursCrees[0].compteBancaire.iban : null,
                fmsPaymentType: elementCommande?.typeReglement ?? 'COMPTANT',
                initialFmsPaymentType: elementCommande?.typeReglement === 'COMPTANT' ? PRICE_TYPES.Today : PRICE_TYPES.Reported,
                method: PAYMENT_METHODS.HYBRIDE,

            });
        }

        return new OrderPaymentInfoModel({
            method: data.paiementInitial.modePaiementSelectionne,
            amount: +data.paiementInitial.montant,
            iban: data?.comptesPayeursCrees ? data.comptesPayeursCrees[0].compteBancaire.iban : null,
            fmsPaymentType: elementCommande?.typeReglement ?? 'COMPTANT',
            initialFmsPaymentType: elementCommande?.typeReglement === 'COMPTANT' ? PRICE_TYPES.Today : PRICE_TYPES.Reported
        });
    }
}
