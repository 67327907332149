import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    CUSTOMER = 'customer',
    CUSTOMER_BYPASS = 'customer_bypass',
    QUALIFICATION = 'qualification'
}

export class CustomerStorage extends StorageData{

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix = 'customer_';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
