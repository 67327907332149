import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    FAI_ELIGE = 'elig',
    FAI_ADDRESS = 'address',
    FAI_CART = 'cart',
    FAI_WIDGET_STEPS = 'steps'
}

export class FaiStorage extends StorageData{

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix = 'fai_';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
