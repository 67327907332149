<div class="map-container">
  <div [ngClass]="isLoading ? 'waiting-data has-text-white' : null" class="loader-backdrop">
    <div class="columns is-marginless is-centered is-vcentered">
      <div class="column is-narrow">
        <div class="control">
          <span class="icon is-huge is-size-1">
            <i class="is-searching"></i>
          </span>
        </div>
      </div>
      <div class="column is-full">
        <p class="s-size-2">Chargement en cours</p>
      </div>
    </div>
  </div>
  <google-map
    (mapClick)="mapClickEventProxy($event)"
    (centerChanged)="centerChangedEventProxy()"
    (zoomChanged)="zoomChangedEventProxy()"
    (mapDblclick)="mapDblclickEventProxy()"
    (tilesloaded)="mapFullyLoaded()"
    (idle)="idleEventProxy()"
    [height]="config.height"
    [width]="config.width"
    [options]="config">
    @for (marker of markers; track marker) {
      <map-marker
        [position]="marker.position"
        [title]="marker.title"
        [options]="marker.options"
        (mapClick)="markerSelectedEventProxy(marker)"
        (mapMouseover)="markerOnMouseOver(marker)"
        (mapMouseout)="markerOnMouseOut(marker)"
      ></map-marker>
    }
  </google-map>
</div>
