import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { CoreComponent } from '../core.component';

@Component({
    selector: 'tlv-rating',
    imports: [CommonModule],
    templateUrl: './rating.component.html',
    styleUrl: './rating.component.scss'
})
export class RatingComponent extends CoreComponent implements OnInit {

    @Input() rating: number;
    @Input() max = 100;
    @Input() count: number;
    @Input() starSize = 2;
    @Input() countSize = 3;

    constructor(public elementRef: ElementRef) {
        super(elementRef);
    }

    public ngOnInit(): void {
        this.classes = ['marginless'];

        if (this.max !== 100){
            this.rating = Math.floor(Number(this.rating) * 100 / this.max);
        }
    }
}
