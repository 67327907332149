import { EdpFms, IProduct } from '@bytel/bytel-sales';

export class DisplayEdpFmsOptionModel extends EdpFms {

    public available: boolean;
    public position: number;
    public firstPage: boolean;

    constructor(product: IProduct, available: boolean, position: number) {
        super(product);
        this.available = available;
        this.position = position;
        this.firstPage = product.firstPage;
    }

    public clone(): this {
        // @ts-expect-error legacy code constructor
        return new this.constructor(this.data, this.available, this.position);
    }
}
