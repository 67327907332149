import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';
import { from as observableFrom, Observable, of, Subject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { OPTIONS_CONFIG } from '../../config';
import { Options, OptionsOauth2 } from '../../interfaces/options.interface';
import { ObjectOfString } from '../../interfaces/services.interface';
import { Oauth2StorageService } from '../oauth2/oauth2-storage.service';
import { Oauth2IframeClass } from './oauth2-iframe.class';

// @dynamic
@Injectable({
    providedIn: 'root'
})
export class Oauth2Service {

    private static readonly DEFAULT_CONFIG: OptionsOauth2 = {
        clientId: 'magento.web.bouyguestelecom.fr'
    };

    public readonly subjectLogout = new Subject<void>();
    public readonly subjectLogin = new Subject<ObjectOfString>();

    public _id_token = '';
    public access_token = '';
    public isConnected = false;
    public jwtPayload: ObjectOfString;
    public keyStorage = 'LAST_PARAMS';
    public playLoad: ObjectOfString;

    public afterLogout: Observable<void> = this.subjectLogout.asObservable();
    public afterLogin: Observable<ObjectOfString> = this.subjectLogin.asObservable();

    protected CLIENT_ID = '';
    protected URL_BASE = '';
    private _initialConfig: Options;

    private readonly COOKIE_SSO: string = 'SSO_ACTIVE';
    private readonly DETECT_KEY_ERROR: string = 'error';
    private readonly DETECT_KEY_SUCCESS: string = 'access_token=';
    private readonly NONCE_STATE_LENGTH: number = 32;
    private readonly REPONSE_TYPE: string = 'id_token token';
    private readonly URL_AUTHORIZE: string = '/authorize';
    constructor(
        @Inject(DOCUMENT) protected document: Document,
        @Inject(OPTIONS_CONFIG) protected optionConfig: () => Options,
        protected cookie: CookieService,
        protected oauth2Storage: Oauth2StorageService,
        protected httpClient: HttpClient) {
        // Set config
        this._initialConfig = optionConfig();
        this._initialConfig.oauth = Object.assign({}, Oauth2Service.DEFAULT_CONFIG, this._initialConfig.oauth);

        this.URL_BASE = this._initialConfig.oauth.base_url;
        this.CLIENT_ID = this._initialConfig.oauth.clientId;
        // Init value
        this._init();
    }

    public get id_token(): string {
        return this._id_token;
    }

    public set id_token(data: string) {
        this._id_token = data;
        if (data) {
            const jwtHelper: JwtHelperService = new JwtHelperService();
            this.jwtPayload = jwtHelper.decodeToken(data);
            this.subjectLogin.next(this.jwtPayload);
            this.oauth2Storage.setItem(this.oauth2Storage.KEY.ID_TOKEN, data);
            this.oauth2Storage.setItem(this.oauth2Storage.KEY.PARAMS, { aud: this.CLIENT_ID, iss: this.URL_BASE });
        } else {
            this.oauth2Storage.removeItem(this.oauth2Storage.KEY.ID_TOKEN);
        }
    }

    /**
     * Check if you have SSO_ACTIVE in cookie and start redirect to Picasso
     */
    public autoLogin(login = false): Observable<boolean> {
        const jwtHelper: JwtHelperService = new JwtHelperService();
        return of(true).pipe(
            tap(() => {
                this.initToken();
            }),
            mergeMap(() => {
                if (!jwtHelper.isTokenExpired(this.oauth2Storage.getItem(this.oauth2Storage.KEY.ID_TOKEN))) {
                    return observableFrom([true]);
                }

                if (this.loggedPicasso()) {
                    return this.loginIframeOauth2();
                } else if (login) {
                    this.document.defaultView.location.href = this.getAuthorizeUrl();
                }

                return of(false);
            })
        );
    }

    public initToken(): void {
        this._useToken(window.location.hash);
    }

    /**
     * Generate URL Authorize
     */
    public getAuthorizeUrl(
        redirect: string = window.document.location.href.replace(window.location.search, ''),
        prompt = false): string {
        const baseUrl: string = this.URL_BASE;
        const clientId = `client_id=${this.CLIENT_ID}`;
        const state = `state=${this._getNewState()}`;
        const nonce = `nonce=${this._getNewNonce()}`;
        const responseType = `response_type=${encodeURI(this.REPONSE_TYPE)}`;
        const redirectUri = `redirect_uri=${encodeURI(redirect)}`;
        const url = `${baseUrl}${this.URL_AUTHORIZE}?${clientId}&${state}&${nonce}&${responseType}&${redirectUri}`;
        return (prompt) ? `${url}&prompt=none` : url;
    }

    public getPersonInfo(): ObjectOfString {
        return this.jwtPayload || null;
    }

    public loggedPicasso(): boolean {
        return !!this.cookie.get(this.COOKIE_SSO);
    }

    /**
     * Automatically logs user with TGTID
     */
    public loginWithTgtId(tgtId): Observable<any> {
        const serviceUrl = `${this._initialConfig.picasso.emptycallBack}?callback=JSONP_CALLBACK`;
        const url = `${this._initialConfig.picasso.login}?tgtId=${tgtId}&service=${serviceUrl}`;
        return this.httpClient.jsonp(url, 'callback');
    }

    public logout(): void {
        this.oauth2Storage.clear();
        const isProd = this._initialConfig?.env?.toLowerCase() === 'prod';
        this.cookie.delete(this.COOKIE_SSO, '/', '.bouyguestelecom.fr', isProd, isProd ? 'None' : 'Lax');
        this.access_token = null;
        this.id_token = null;
        this.isConnected = false;
        this.subjectLogout.next();
    }

    public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        const params = route.queryParams;
        if (Object.keys(params).length > 0) {
            const date = new Date().getTime() + 5 * 60 * 1000;
            this.oauth2Storage.setUniversalItem(this.keyStorage, JSON.stringify(params), date);
        }

        return this.autoLogin();
    }

    public updateIsConnected(): boolean {
        const jwtHelper: JwtHelperService = new JwtHelperService();
        this.isConnected = !jwtHelper.isTokenExpired(this.oauth2Storage.getItem(this.oauth2Storage.KEY.ID_TOKEN));
        return this.isConnected;
    }

    public login(redirectUrl: string = this.document.defaultView.location.href): Observable<boolean> {
        if (this.loggedPicasso()) {
            return this.loginIframeOauth2();
        } else {
            this.document.defaultView.location.href = this.getAuthorizeUrl(redirectUrl);
        }
        return of(false);
    }

    /**
     * Retirec to Picasso
     */
    public loginIframeOauth2(): Observable<boolean> {
        return new Observable((obs) => {
            let errorIframe = true;
            let oauthIframe: HTMLIFrameElement = null;
            setTimeout(() => { // ERROR
                if (!errorIframe) {
                    return;
                }
                obs.error('IMPOSSIBLE_CONNEXION_2');
                obs.complete();
            }, 4000);
            window.addEventListener('message', (e: MessageEvent) => {
                errorIframe = false;
                if (!oauthIframe || e.source !== oauthIframe.contentWindow) {
                    return;
                }
                const data = e?.data || '';
                if (this._useToken(data as string)) {
                    this.updateIsConnected();
                    obs.next(true);
                } else {
                    if (!this._detectTokenError(this.document.defaultView.location.href)) {
                        this.document.defaultView.location.href = this.getAuthorizeUrl();
                        obs.error('IMPOSSIBLE_CONNEXION_3');
                    } else {
                        this.document.defaultView.location.href = this._initialConfig.oauth.failure_url;
                    }
                }
                window.removeEventListener('message', () => {}); // eslint-disable-line
                obs.complete();
            }, false);
            oauthIframe = Oauth2IframeClass.injectIframe(this.getAuthorizeUrl(`${this.document.location.origin}/oauth2.html`, true));
        });
    }

    /**
     * Check if hash is error response
     */
    private _detectTokenError(hash: string): boolean {
        return (hash.includes(this.DETECT_KEY_ERROR));
    }

    /**
     * Check if hash is success response
     */
    private _detectTokenSuccess(hash: string): boolean {
        return (hash.includes(this.DETECT_KEY_SUCCESS));
    }

    /**
     * Remove '#/'
     * Extract param from oauth hash
     */
    private _extractParam(hash: string): ObjectOfString {
        const tab: string[] = hash.split('&');
        const obj: ObjectOfString = {};
        for (let i = 0; i < tab.length; i++) {
            tab[i] = tab[i].replace('#', '');
            tab[i] = tab[i].replace('/', '');
            tab[i] = tab[i].replace('?', '');
            const keyValue: string[] = tab[i].split('=');
            obj[keyValue[0]] = keyValue[1];
        }
        return obj;
    }

    /**
     * Generate key chaine and set in oauth2Storage for check after redirection
     */
    private _getNewNonce(): string {
        const currentNonce = this._getNonce();
        if (currentNonce) {
            return currentNonce;
        }
        const nonce: string = this._makeNonceOrState();
        this.oauth2Storage.setItem(this.oauth2Storage.KEY.NONCE, nonce);
        return nonce;
    }

    /**
     * Generate key chaine and set in oauth2Storage for check after redirection
     */
    private _getNewState(): string {
        const currentState = this._getState();
        if (currentState) {
            return currentState;
        }
        const state: string = this._makeNonceOrState();
        this.oauth2Storage.setItem(this.oauth2Storage.KEY.STATE, state);
        return state;
    }

    /**
     * State from storage
     */
    private _getNonce(): string {
        return this.oauth2Storage.getItem(this.oauth2Storage.KEY.NONCE);
    }

    /**
     * State from storage
     */
    private _getState(): string {
        return this.oauth2Storage.getItem(this.oauth2Storage.KEY.STATE);
    }

    private _init(): void {
        const jwtHelper: JwtHelperService = new JwtHelperService();
        const idToken = this.oauth2Storage.getItem(this.oauth2Storage.KEY.ID_TOKEN);
        this.jwtPayload = jwtHelper.decodeToken(idToken);
        this._useToken(window.location.hash);
    }

    /**
     * Generate key chaine for State
     */
    private _makeNonceOrState(): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < this.NONCE_STATE_LENGTH; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length)); // NOSONAR
        }
        return text;
    }

    private _useToken(hash: string): boolean {
        if (this._detectTokenSuccess(hash)) {
            // Succes oauth redirect
            const oauthParams: ObjectOfString = this._extractParam(hash);
            if (oauthParams.state === this._getState()) {
                const jwtHelper: JwtHelperService = new JwtHelperService();
                if (jwtHelper.isTokenExpired(oauthParams.id_token)) {
                    return false;
                }
                this.oauth2Storage.setItem(this.oauth2Storage.KEY.ACCESS_TOKEN, oauthParams.access_token);
                this.oauth2Storage.setItem(this.oauth2Storage.KEY.ID_TOKEN, oauthParams.id_token);
                this.access_token = oauthParams.access_token;
                this.id_token = oauthParams.id_token;
                return true;
            }
        } else if (this._detectTokenError(hash)) {
            // Error oauth redirect
            this.document.defaultView.location.href = this._initialConfig.oauth.failure_url;
            return false;
        } else if (this.oauth2Storage.getItem(this.oauth2Storage.KEY.ID_TOKEN)) {
            // Load from storage
            this.access_token = this.oauth2Storage.getItem(this.oauth2Storage.KEY.ACCESS_TOKEN);
            this.id_token = this.oauth2Storage.getItem(this.oauth2Storage.KEY.ID_TOKEN);
        }
        return false;
    }
}
