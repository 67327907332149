import {FORM_PATTERNS} from '../constants/fai';

export const GetDeepValue = (obj, path): any => {
    for (let i = 0, pathTab = path.split('.'), len = pathTab.length; i < len; i++){
        obj = obj[pathTab[i]];
        if (!obj){break;}
    }
    return obj;
};

export const flattenObj = (obj): Record<string, unknown> => {
    const res = {};
    Object.keys(obj).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key]) {
            res[key] = obj[key];
        }
    });
    return res;
};

export const SortObjByProp = <T>(a: T, b: T): (field: string) => number => (field): number => {
    const valA = GetDeepValue(a, field) ?? 0;
    const valB = GetDeepValue(b, field) ?? 0;

    if (FORM_PATTERNS.ANY_NUMBER.test(valA)){
        return (parseFloat(valA) > parseFloat(valB) ? 1 : -1);
    } else {
        return (valA > valB ? 1 : -1);
    }
};

export const Distinc = <T>(objs: T[], path: string): T[] => objs.reduce((accumulator, obj) => {
    if (!!obj[path] && !accumulator.find(objAccumulator => !!objAccumulator[path] && objAccumulator[path] === obj[path])) {
        accumulator.push(obj);
    }
    return accumulator;
}, []);

export const Binder = <T,K extends keyof T>(instance: T,fnc: K): T[K] => (instance[fnc] as any).bind(instance) as T[K];
