import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import { IConsulterOpportuniteGP, IOpportuniteOp, IRechercherOpportuniteGP } from '@interfaces/opportunity.interface';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OpportunityRepository {

    constructor(private oauth2Resource: Oauth2ResourcesService) { }

    public rechercherOpportunite(idOpportuniteGP: string): Observable<string[]> {
        return this.oauth2Resource
            .useSalesApi()
            .rechercherOpportunite(idOpportuniteGP)
            .get()
            .pipe(
                map((opportunites: IRechercherOpportuniteGP) =>
                    opportunites?.opportunites?.map((opportunite) => opportunite.idOpportuniteGP) ||
                    [])
            );
    }

    public consulterOpportunite(idOpportuniteGP: string): Observable<IOpportuniteOp> {
        return this.oauth2Resource
            .useSalesApi()
            .consulterOpportunite(idOpportuniteGP)
            .get()
            .pipe(
                map((opportunites: IConsulterOpportuniteGP) =>
                    ({ idOpportunite: idOpportuniteGP, idOffre: opportunites.libelleOffrePrincipale })
                ),
                catchError(() => of({ idOpportunite: '', idOffre: '' }))
            );
    }
}
