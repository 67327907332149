import { Model } from '@models/model';
import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class SlotModel extends Model{

    @Expose({toClassOnly: true})
    id: string;

    @Expose({toClassOnly: true})
    start: Date;

    @Expose({toClassOnly: true})
    end: Date;

    @Expose({toClassOnly: true})
    providerId: string;

    @Expose({toClassOnly: true})
    calendarType: string;

    @Expose({toClassOnly: true})
    EligibilitySpecificSlots?: boolean;

    constructor(data: Partial<SlotModel>) {
        super(data);
    }

}
