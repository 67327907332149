import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { BtlCmsService } from '../../services/btl-cms.service';

// /** @dynamic */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'bytel-cms-block',
    templateUrl: './cms-block.component.html',
    styleUrls: ['./cms-block.component.scss'],
    standalone: false
})
export class CmsBlockComponent implements OnInit {
    @Input() extraClass: string;
    @Input() renderNullBlock = true;
    @Input() replaceElement = false; // TO USE ONLY FOR STATIC BLOCK
    @Input() html: string;
    @Input() errorMsg = 'Erreur lors de la récupération du contenu';
    @Input() catchError = false;
    @Input() addIndexHtmlToUrl = true;
    @Input() battlefront = false;
    @Input() public set blockId(id: string) {
        this._blockId = id;
        if (this._loaded) {
            this.renderBlock();
        }
    }
    public get blockId(): string {
        return this._blockId;
    }

    @Output() public contentIsLoaded: EventEmitter<boolean> = new EventEmitter<boolean>(true);

    private _loaded = false;
    private _blockId: string;

    constructor(@Inject(DOCUMENT) protected document: Document,
                protected renderer: Renderer2,
                protected element: ElementRef,
                protected http: HttpClient,
                protected cmsService: BtlCmsService) {
    }

    public ngOnInit(): void {
        this.renderBlock();
        this._loaded = true;
    }

    public renderBlock(): void {
        if (!this.html) {
            this.cmsService.getContent(this.blockId, this.battlefront, this.addIndexHtmlToUrl)
                .subscribe(({next: (data: any) => {
                    this.resetBlock();
                    if (this.renderNullBlock || this.blockId) {
                        this.injectHtml(data.html);
                    }
                }, error: () => {
                    this.resetBlock();
                    this.contentIsLoaded.emit(false);
                    if (this.catchError) {
                        this.injectHtml(this.errorMsg);
                    }
                }}));
        } else {
            this.injectHtml(this.html);
        }

    }

    public resetBlock(): void {
        const childElements = this.element.nativeElement.children;
        for (const child of childElements) {
            this.renderer.removeChild(this.element.nativeElement, child);
        }
    }

    protected injectHtml(html: string): void {
        if (html) {
            const domHtml = this.document.createRange().createContextualFragment(html);
            if (this.replaceElement) {
                this.element.nativeElement.parentNode.replaceChild(domHtml, this.element.nativeElement);
            } else {
                const container: HTMLElement = this.document.createElement('div');
                // innerHTML native property never execute script, that's why injecting string directly was prefered
                container.classList.add('cms-container');
                if (this.extraClass) {
                    container.classList.add(this.extraClass);
                }
                container.appendChild(domHtml); // String to HTML
                this.renderer.appendChild(this.element.nativeElement, container);
            }
            this.contentIsLoaded.emit(true);
        }
    }
}
