<ng-template #ngContent>
  <ng-content></ng-content>
</ng-template>

<ng-container>
  <a #elm [ngClass]="{'is-hidden': isButton}" [attr.disabled]="isDisabled()" [attr.tabindex]="" [attr.href]="href" [style.backgroundColor]="color">
    @if (icon) {
      <span [ngClass]="'icon is-' + iconSize">
        <i [ngClass]="icon"></i>
      </span>
    }
    @if (!isButton) {
      <ng-container *ngTemplateOutlet="ngContent"></ng-container>
    }
  </a>
  <button #btlElm [ngClass]="{'is-hidden': !isButton}" [attr.disabled]="isDisabled()" [attr.tabindex]="" [attr.href]="href" [style.backgroundColor]="color">
    @if (icon) {
      <span [ngClass]="'icon is-' + iconSize">
        @if (icon) {
          <i [ngClass]="icon"></i>
        }
      </span>
    }
    @if (isButton) {
      <ng-container *ngTemplateOutlet="ngContent"></ng-container>
    }
  </button>
</ng-container>
