import { Injectable } from '@angular/core';
import { MainCartModel } from '@models/cart/main-cart.model';
import { PortabilityModel } from '@models/cart/portability.model';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';
import { classToPlain, plainToClass } from 'class-transformer';

@Injectable({
    providedIn: 'root'
})
export class PortabilityService {

    private static readonly STORAGE_KEY = 'PORTABILITY';
    public currentCartModel: MainCartModel;
    private _portability: Record<string, PortabilityModel> = {};

    constructor(){
        let portability;
        const portaData = CheckoutStorage.getItem(PortabilityService.STORAGE_KEY);
        if (portaData) {
            portability = plainToClass(PortabilityModel, portaData);
            this._portability = portability;
        }
    }

    public get portability(): PortabilityModel {
        return this._getPortabilityFromCartModel();
    }

    public set portability(porta: PortabilityModel) {
        if (porta?.lineType) {
            this._portability[porta.lineType] = porta;
        }
    }

    public update(data: PortabilityModel): void {
        if (data?.lineType) {
            this._portability[data.lineType] = data;
            this._save();
        }
    }

    public clear(): void{
        this._portability = {};
        this._save();
    }

    public getPortabilityFromCartModel(quoteIndex: number): PortabilityModel {
        return this._getPortabilityFromCartModel(quoteIndex);
    }

    private _save(): void {
        CheckoutStorage.setItem(PortabilityService.STORAGE_KEY, classToPlain(this._portability));
    }

    private _getPortabilityFromCartModel(quoteIndex: number = null, cartModel: MainCartModel = this.currentCartModel): PortabilityModel {
        if (!cartModel) {
            return;
        }
        const quoteContext: string = cartModel.getQuote(quoteIndex >= 0 ? quoteIndex : cartModel.currentQuoteIndex)?.context?.id;
        return this._portability && Object.prototype.hasOwnProperty.call(this._portability, quoteContext) ? this._portability[quoteContext] : null;
    }

}
