import { IFaiCity, IFaiComplement, IFaiStreet } from '@interfaces/api/fai-webshop.interface';
import { AddressModel } from '@models/cart/address.model';
import { Model } from '@models/model';

export class FaiAddressModel extends Model{

    public postal: string;
    public city: IFaiCity;
    public street: IFaiStreet;
    public street_number: string;
    public complement?: string | IFaiComplement;
    public streetNumberCode: string;

    public get complementLibelle(): string{
        return (this.complement as IFaiComplement)?.libelle ?? (this.complement as string);
    }
    public get complementCode(): string{
        return (this.complement as IFaiComplement)?.code || (this.complement as string);
    }

    constructor(data: Partial<FaiAddressModel>){
        super(data);
    }

    public getAddressModel(): AddressModel{
        return new AddressModel({
            postalCode: this.postal,
            city: this.city.libelle,
            street: this.street.libelle,
            streetNumber: this.street_number,
            complement: this.complementLibelle
        });
    }

    public get inline(): string{
        let libelle: string;
        if (typeof this.complement === 'string'){
            libelle = this.complement;
        } else {
            libelle = this.complement?.libelle;
        }
        const complementLibelle = libelle ? ` ${this.complementLibelle} ` : ' ';
         
        return `${this.street_number ? this.street_number : ''}${complementLibelle}${this.street.libelle} ${this.city.libelle} ${this.postal}`;
    }
}
