import { IStorageInterface } from '@interfaces/storage.interface';

export abstract class StorageData {

    protected static sessionStorageKeys: string[] = [];
    protected static localStorageKeys: string[] = [];
    protected static prefix = '';

    /**
     * Use item of IStorageInterface insterad of simple key value to make storage expires
     */
    public static setItem(key: string, value: unknown, expires: number = null): void {
        const storageObject: IStorageInterface = {data: value, expiresTimestamp: expires};
        this.removeItem(key);
        this._getStorageInstance(key).setItem(this.prefix + key, JSON.stringify(storageObject));
    }

    /**
     * Return item from storage
     * If expiresTimestamp is not null and lower then Now remove item and returns null
     */
    public static getItem<T = any>(key: string): T {
        let item: T;
        const data: string = this._getStorageInstance(key).getItem(this.prefix + key);

        if (data !== null) {
            const storageObject: IStorageInterface = JSON.parse(data);
            if (storageObject.expiresTimestamp !== null) {
                if (storageObject.expiresTimestamp  < new Date().getTime()) {
                    this.removeItem(key);
                } else {
                    item = storageObject.data;
                }
            } else {
                item = storageObject.data;
            }
        }
        return item;
    }

    public static removeItem(key: string): void {
        this._getStorageInstance(key).removeItem(this.prefix + key);
    }

    public static clear(storageInstance?: Storage): void {
        if (storageInstance instanceof Storage) {
            this.clearAllPrefix(storageInstance);
        } else {
            this.clearAllPrefix(sessionStorage);
            this.clearAllPrefix(localStorage);
        }
    }

    protected static _getStorageInstance(key: string): Storage {
        let  storage: Storage;
        if (this.sessionStorageKeys.includes(key)) {
            storage = sessionStorage;
        } else if (this.localStorageKeys.includes(key)) {
            storage = localStorage;
        } else {
            storage = localStorage;
        }
        return storage;
    }

    protected static clearAllPrefix(storageInstance: Storage): void {
        const keys: string[] = Object.keys(storageInstance);
        keys.forEach(key => {
            if ( key.startsWith(this.prefix)) {
                storageInstance.removeItem(key);
            }
        });
    }
}
