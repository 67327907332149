import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    STEPPER_FUNNEL = 'stepper-wetofunnel'
}

export class WetoStorage extends StorageData {

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix = 'weto-';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
