import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SharedService {

    public static DEFAULT_API_ERROR_MESSAGE = 'Un problème technique est survenu, merci de réessayer.';
    private subjectCalendar = new Subject<boolean>();

    public static handleErrorMessage(err: Record<string, any>): string {
        const error: string = err?.error?.error_description || SharedService.DEFAULT_API_ERROR_MESSAGE;
        if (err?.message) {
            console.warn(`Error from code: ${err?.message}`);
        }
        return `Erreur: ${(error || SharedService.DEFAULT_API_ERROR_MESSAGE)}`;
    }

    public sendEventLoadCalendar(): void {
        this.subjectCalendar.next(true);
    }

    public getEventLoadCalendar(): Observable<boolean>{
        return this.subjectCalendar.asObservable();
    }

    public sendEventValidationStatus(valid: boolean): void {
        this.subjectCalendar.next(valid);
    }

    public getEventValidationStatus(): Observable<boolean>{
        return this.subjectCalendar.asObservable();
    }

}
