export const  PAYMENT_DEFAULT_METHOD = 'CASH';
export const NO_PAYMENT = 'SANS_PAIEMENT';

export const FUNDING_METHODS = {
    CASH : {
        methods: [
            'CB',
            'PAYPAL',
            'PAYLIB',
            'HYBRIDE',
            'SANS_PAIEMENT',
            'COMPTANT',
        ],
        label: 'Paiement comptant'
    },
    CREDIT_3X: {
        methods: ['CREDIT_3X'],
        label: 'Paiement en 3x sans frais'
    },
    CREDIT_12X: {
        methods: ['CREDIT_MENSUEL'],
        label: 'Paiement en 12x avec 1euro.com'
    },
    CREDIT_24X: {
        methods: ['CREDIT_MENSUEL'],
        label: 'Paiement en 24x avec 1euro.com'
    },
    EDP: {
        methods: [
            'CB',
            'PAYPAL',
            'PAYLIB',
            'HYBRIDE',
            'MULTI_PRODUIT'
        ],
        label: 'Facilité de paiement'
    },
};

export enum COFIDIS_METHODS {
    CREDIT_3X = 23,
    CREDIT_12X = 24,
    CREDIT_24X = 25
}

export enum MODE_FINANCEMENT_SERVICE {
    CASH = 'COMPTANT',
    COFIDIS_X3 = 'COFIDIS_X3',
    COFIDIS_X12 = 'COFIDIS_X12',
    COFIDIS_X24 = 'COFIDIS_X24',
    EDP = 'MULTI_PRODUIT',
    BARE_EDP = 'EDP',
    YOUNITED = ' YOUNITED'
}

export const ALLOWED_PAYMENT_METHODS = {
    CASH: {
        label: 'Paiement comptant',
        method: 'CASH'
    },
    SPREAD: {
        label: 'Facilité de paiement',
        method: 'SPREAD'
    }
};

export const PAYMENT_METHODS: Record<string, string> = {
    CB: 'CB',
    PAYPAL: 'PAYPAL',
    PAYLIB: 'PAYLIB',
    HYBRIDE: 'HYBRIDE',
    CREDIT_3X: 'CREDIT_3X',
    CREDIT_MENSUEL: 'CREDIT_MENSUEL'
};

export enum PAYMENT_MODE {
    PRELEVEMENT = 'PRELEVEMENT',
    CB_OU_CHEQUE = 'CB_OU_CHEQUE'
}
export interface IFingerPrintParams {
    key: string;
    value: unknown;
}

export interface IPaymentFormData {
    method: string;
    url: string;
    inputs?: {name: string; value: string}[];
}

export interface IPaymentResponse {
    redirectUrl: string;
    formData: IPaymentFormData;
}
