
export interface IDeliveryInfoAdresse {
    codePostal: string;
    ville: string;
    rue: string;
    numeroRue: string;
    complementAdresse: string;
    nom: string;
    prenom: string;
    civilite: string;
}
export interface IMethodInfo {
    gencode: string;
    entiteReseauDistribution: {
        codeEnseigne: string;
        nom: string;
        codePointVente: string;
    };
    pointRelais: {
        codeIdentification: string;
        libelle: string;
        codeSecteurLivraison: string;
    };
}

export interface IlivraisonsAdresse {
    codePostal: string;
}

export interface IlivraisonsProduit {
    gencode: string;
    idVirtuel: number;
    modeDeFinancement: string;
}

export interface IlivraisonsParcours {
    type: string;
    idVirtuel: number;
    produits?: IlivraisonsProduit[];
}

export interface IlivraisonsPanier {
    parcours: IlivraisonsParcours[];
}

export interface IlivraisonsDisponibles {
    noPersonne?: any;
    reexpedition?: any;
    contractId?: any;
    adresse?: IlivraisonsAdresse;
    panier?: IlivraisonsPanier;
}

export interface IconsulterPolitiqueTarifairePaniersApresVente {
    elementsCommandesModifiables: ElementsCommandesModifiable[];
}

export interface ElementsCommandesModifiable {
    id:                 string;
    politiqueTarifaire: string;
}

export enum POLITIQUE_TARIFAIRE {
    GRATUIT_COMPTANT = 'GRATUIT_COMPTANT',
    PRIX_INFERIEUR_OU_EGAL_COMPTANT = 'PRIX_INFERIEUR_OU_EGAL_COMPTANT',
    PRIX_INFERIEUR_OU_EGAL_DIFFERE = 'PRIX_INFERIEUR_OU_EGAL_DIFFERE',
}
