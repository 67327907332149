import { MobileTakeBack, PromotionsModel } from '@bytel/bytel-sales';
import { MOBILE_RECOVERY_STATUS } from '@interfaces/mobile-recovery-interface';
import { Model } from '@models/model';
import { Type } from 'class-transformer';

import { SurveyMobileRecoveryModel } from './quote-mobile-recovery.model';

export class MobileRecoveryPartnerDataModel extends Model{
    id: string;
    quoteId: string;
    maxAmount: number;
    minAmount: number;
    status: MOBILE_RECOVERY_STATUS;
    bonus: number;
    insuranceAmount: number;
    hasInsurance = false;
    @Type(()=> SurveyMobileRecoveryModel)
    surveys: SurveyMobileRecoveryModel[] = [];

    constructor(data: Partial<MobileRecoveryPartnerDataModel>){
        super(data);
    }
}

export class MobileRecoveryModel extends MobileTakeBack<MobileRecoveryPartnerDataModel>{
    //    estimatedAmount: number;
    @Type(()=>MobileRecoveryPartnerDataModel)
    partnerData: MobileRecoveryPartnerDataModel;

    @Type(()=> PromotionsModel)
    public promotions: PromotionsModel;

    constructor(data: Partial<MobileRecoveryModel> = {}) {
        super(data);
        this.gencode = 'RMBC000000';
        this.data.gencode = 'RMBC000000';
        if (!data.partnerData){
            this.partnerData = new MobileRecoveryPartnerDataModel({});
        }
    }

    public isRecoveryCompleted(): boolean {
        return this.partnerData.status === MOBILE_RECOVERY_STATUS.COMPLETED;
    }

}
