import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {Observable, of} from 'rxjs';
import {QualificationService} from './qualification.service';
import {catchError, concatMap, delayWhen, map, mergeMap, tap} from 'rxjs/operators';
import {HotToastService} from '@ngneat/hot-toast';
import {FaiEligibilityService} from './fai/fai-eligibility.service';
import {FaiFunnelService} from './fai/fai-funnel.service';
import {DisplayError} from '@models/error/display.error';
import {SalesForceRepository} from '@repositories/sales-force.repository';
import {SaleForceInfosModel} from '@models/sale-force-infos.model';
import {FaiAddressModel} from '@models/fai/fai-address';
import {AddressModel} from '@models/cart/address.model';
import {OrderService} from '@services/order.service';
import {OrderModel} from '@models/order/order.model';
import {QualificationModel} from '@models/qualification';
import bind from '../helper/decorators/bind';
import {CustomerService} from './customer/customer.service';
import {CustomerSearchModel} from '@models/customer/customer-search.model';
import {CustomerProSearchModel} from '@models/customer/customer-pro-search.model';
import {FaiLogementModel} from '@models/fai/fai-logement.model';
import {CustomerProDetailsModel} from '@models/customer/customer-pro-details.model';
import {CustomerDetailsModel} from '@models/customer/customer-details.model';
import {ConfigurationService} from '../common-modules/services/configuration.service';
import {IconsulterPolitiqueTarifairePaniersApresVente, POLITIQUE_TARIFAIRE} from '@interfaces/delivery.inteface';

@Injectable({
    providedIn: 'root'
})
export class SalesForceService {

    public prefilledInfo: SaleForceInfosModel = new SaleForceInfosModel({});

    constructor(
        private qualificationService: QualificationService,
        private faiFunnelService: FaiFunnelService,
        private eligibilityService: FaiEligibilityService,
        private router: Router,
        private salesForceRepository: SalesForceRepository,
        private orderService: OrderService,
        private toastService: HotToastService,
        private customerService: CustomerService,
        private configService: ConfigurationService
    ) {
        this.prefilledInfo = this.salesForceRepository.getData();
        if (this.prefilledInfo.salesType === 'FAI' && this.prefilledInfo.address && !eligibilityService.currentCart?.eligibility &&
        !this.configService?.data_refconf?.fai?.widget?.active){
            this.replayEligibilityFromData(this.prefilledInfo.address).subscribe();
        }
        if (this.prefilledInfo.hasQalificationInfos()){
            this.qualificationService.update(
                new QualificationModel({...this.qualificationService.qualification, ...this.prefilledInfo.getQualification()})
            );
        }
    }


    public resolve(): Observable<void> {
        this.prefilledInfo = this.salesForceRepository.getData();
        if (this.prefilledInfo.orderId && !this.prefilledInfo.order){
            return this._loadRecoveryOrder(this.prefilledInfo);
        }
        return of(null);
    }

    public setFaiHousingAllReadyDisplayed(): void{
        this.prefilledInfo.faiHousingDisplayed = true;
        this.save(this.prefilledInfo);
    }

    public setFms(politiqueTarifairePanierApresVente: IconsulterPolitiqueTarifairePaniersApresVente): void {
        const fms = this.prefilledInfo.order.offer[0].elementsCommandes
            .find( (elementCommande) => elementCommande.type === 'FRAIS' && elementCommande.nature === 'FRAIS_MISE_EN_SERVICE' );

        const elementCommandeModifiableFms = politiqueTarifairePanierApresVente.elementsCommandesModifiables
            .find((elementCommandeModifiable) => elementCommandeModifiable.id === fms.id);

        this.prefilledInfo.order.cart.payment.politiqueTarifaire =
            elementCommandeModifiableFms.politiqueTarifaire === POLITIQUE_TARIFAIRE.PRIX_INFERIEUR_OU_EGAL_DIFFERE
                ?  POLITIQUE_TARIFAIRE.PRIX_INFERIEUR_OU_EGAL_DIFFERE : POLITIQUE_TARIFAIRE.PRIX_INFERIEUR_OU_EGAL_COMPTANT;
    }
    public save(data: SaleForceInfosModel): void {
        this.salesForceRepository.save(data);
    }

    public replayEligibilityFromData(address: AddressModel): Observable<boolean> {
        return this.faiFunnelService.getFaiAddress(address)
            .pipe(
                tap((faiAddress) => this._updatePrefilledAddress(faiAddress)),
                // @fix: max pipe size
                concatMap((faiAddress) => this.eligibilityService.getAndSaveLogement(faiAddress, {panier:this.faiFunnelService.faiCart})),
                concatMap(() => of(this.eligibilityService.currentCart?.logements ?? []).pipe(
                    map((housings: FaiLogementModel[]) => housings.find(this._findLogementFromOrder)),
                     
                    mergeMap((housing: FaiLogementModel) => (this.prefilledInfo.order && !this.prefilledInfo.order?.fai?.isCDL && housing) ? this.eligibilityService.getAndSaveEligibility(
                        new FaiLogementModel({
                            ...this.prefilledInfo.order?.fai?.logement,
                            codeLogement: housing.codeLogement
                        })
                    ) : of(null))
                )),
                catchError((err: DisplayError) => {
                    this.toastService.warning(`Erreur reprise commande: ${err?.message}`, {duration: 5000});
                    this.prefilledInfo.eligibilityError = err;
                    // Avoid endless loop by avoiding elig replay on bad address
                    this.prefilledInfo.address = null;
                    this.save(this.prefilledInfo);
                    return of(false);
                }),
                map(()=>true)
            );
    }

    @bind
    private _findLogementFromOrder(housing: FaiLogementModel): (h: FaiLogementModel) => boolean {
        return (): boolean => housing.ndi === this.prefilledInfo.order.fai.logement.ndi ||
            (housing.numeroVoie === this.prefilledInfo.order.fai.logement.numeroVoie &&
                housing.voie === this.prefilledInfo.order.fai.logement.voie &&
                housing.commune === this.prefilledInfo.order.fai.logement.commune &&
                housing.codePostal === this.prefilledInfo.order.fai.logement.codePostal
            );
    }

    private _loadRecoveryOrder(prefilledInfo: SaleForceInfosModel): Observable<void>{
        // return this.orderService.getCommercialOrder(prefilledInfo.orderId)
        return this.orderService.getPanierRetoursRepriseParcours(prefilledInfo.orderId)
            .pipe(
                tap((order)=>{
                    if (!order  || !order.reusable){
                        this._clearPrefilledOrder();
                        this.router.navigate(['/erreur-reprise-commande']);
                        throw new Error('Commande non reprenable');
                    }
                }),
                mergeMap((order)=>this.customerService.setCustomerFromId(order.personId).pipe(
                    map((customer: (CustomerDetailsModel | CustomerProDetailsModel)) => ({
                        order,
                        ...(customer ? {customer} : {})
                    })),
                    catchError(()=>of({order, customer: null}))
                )),
                tap(this._addOrderToPrefilledInfo),
                delayWhen(({order})=>
                    order.fai.address?.getAddressModel() && !this.configService?.data_refconf?.fai?.widget?.active ?
                        this.replayEligibilityFromData(order.fai.address.getAddressModel()) : of(true)
                ),
                tap(()=>{
                    this.qualificationService.update(new QualificationModel(
                        {...this.qualificationService.qualification, calltype: 'in', campaign: null})
                    );
                    this.router.navigate([
                        'fai',
                        this.eligibilityService.currentCart?.eligibility ? 'housing' : 'address'
                    ]);
                }),
                map(()=>null)
            );
    }

    @bind
    private _addOrderToPrefilledInfo(data: {order: OrderModel; customer: (CustomerSearchModel | CustomerProSearchModel)}): void{
        this.prefilledInfo.address = data.order.fai.address?.getAddressModel();
        this.prefilledInfo.order = data.order;
        this.prefilledInfo.salesType = 'FAI';
        this.prefilledInfo.customer = data?.customer;
        this.save(this.prefilledInfo);
    }

    private _clearPrefilledOrder(): void {
        this.prefilledInfo.order = null;
        this.prefilledInfo.orderId = null;
        this.save(this.prefilledInfo);
    }

    private _updatePrefilledAddress(faiAddress: FaiAddressModel): void {
        this.prefilledInfo.address = faiAddress.getAddressModel();
        if (this.prefilledInfo.order) {
            this.prefilledInfo.order.fai.address = faiAddress;
        }
        this.save(this.prefilledInfo);
    }

}
