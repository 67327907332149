import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    SCORING = 'scoring',
}

export class ScoringStorage extends StorageData{

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix = 'checkout_';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
