import { Component, Input } from '@angular/core';

@Component({
    selector: 'tlv-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class LoaderComponent {

    @Input() loadingText = 'Chargement en cours...';
    @Input() isAnimated = true;
    @Input() iconSize = 'small';
}
