import { Injectable } from '@angular/core';
import { CUSTOMER_CATEGORY, Equipment } from '@bytel/bytel-sales';
import {
    BasicObject,
    Oauth2ResourcesService,
    shapeIconDeposit,
    shapeIconDepositSelected,
    shapeIconHomeLocation,
    Store,
    StoreHours
} from '@common-modules';
import { IApiModesLivraison } from '@interfaces/api/mode-livraison.interface';
import { IconsulterPolitiqueTarifairePaniersApresVente } from '@interfaces/delivery.inteface';
import { IStoreCheckout } from '@interfaces/store-checkout.interface';
import { AddressModel } from '@models/cart/address.model';
import { DeliveryInfoModel } from '@models/cart/delivery-info.model';
import { MainCartModel } from '@models/cart/main-cart.model';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';
import { CustomerService } from '@services/customer/customer.service';
import { classToPlain, plainToClass } from 'class-transformer';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WEEK_DAYS } from '../constants/date';
import { STORE_TYPE } from '../constants/delivery';
import { SalesApiHelper } from '../helper/sales-api.helper';

@Injectable({
    providedIn: 'root'
})
export class DeliveryRepository {

    constructor(private oauth2Resource: Oauth2ResourcesService, private customerService: CustomerService) {}

    public consulterPolitiqueTarifairePaniersApresVente(id?: string): Observable<IconsulterPolitiqueTarifairePaniersApresVente> {
        return this.oauth2Resource
            .consulterPolitiqueTarifairePaniersApresVente(id)
            .get();
    }

    public getDeliveryAvailable(cart: MainCartModel, address: AddressModel): Observable<DeliveryInfoModel[]>{

        return this.oauth2Resource
            .ventes()
            .panier()
            .livraisonsDisponibles()
            .addCache()
            .setLocalService()
            .useSalesApi()
            .post({
                ...SalesApiHelper.GetCartMixedParams(cart),
                typeClient: this.customerService.isCustomerOrProspectPro() ? CUSTOMER_CATEGORY.PRO : CUSTOMER_CATEGORY.GP,
                adresse: { codePostal: address.postalCode },
                noPersonne: cart?.idPerson
            })
            .pipe(
                map((data: IApiModesLivraison) => data.panier.parcours.flatMap( (parcours) => parcours.modesLivraison.map( (mode)=>
                    new DeliveryInfoModel({
                        gencode: mode.gencode,
                        libelle: mode.libelle,
                        idVirtuel: parcours.idVirtuel,
                        estimatedDeliveryDate: new Date(mode.dateLivraisonEstimee),
                        lateEstimatedDeliveryDate: new Date(mode.dateLivraisonEstimeeAuPlusTard),
                        price: mode.prix
                    })
                ))));
    }

    public saveSelected(deliveryInfo: DeliveryInfoModel): void{
        CheckoutStorage.setItem(CheckoutStorage.KEYS.DELIVERY,classToPlain(deliveryInfo));
    }

    public deleteSelected(): void{
        CheckoutStorage.removeItem(CheckoutStorage.KEYS.DELIVERY);
    }

    public getSelected(): DeliveryInfoModel{
        const data = CheckoutStorage.getItem(CheckoutStorage.KEYS.DELIVERY);
        if (data){
            return plainToClass(data, DeliveryInfoModel);
        }
    }

    public getClickAndCollect(longitude: number,latitude: number,equipement?: Equipment): Observable<any> {
        // fix api clickAndCollect, because we can search relais ON position
        latitude = parseFloat((latitude - 0.01).toFixed(15));
        longitude = parseFloat((longitude - 0.01).toFixed(15));
        return this.oauth2Resource
            .ventes()
            .clickAndCollect()
            .addCache()
            .setLocalService()
            .useSalesApi()
            .setParams({
                latitude,
                longitude,
                produits: equipement.gencode
            })
            .get()
            .pipe(
                map((data: any) => (data.items || []).map(
                    (store, index) => this.generateStoreFromData({...store, listIndex: index})
                )),
                catchError(error => {
                    console.warn(error);
                    return of([]);
                })
            );
    }

    public getRelaisColis(longitude: number,latitude: number): Observable<IStoreCheckout[]>{
        return this._getStoreRelais(longitude,latitude,'RELAIS_COLIS');
    }

    public getPointRelais(longitude: number,latitude: number): Observable<IStoreCheckout[]>{
        return this._getStoreRelais(longitude,latitude,'CHRONOPOST');
    }

    public generateStoreFromDeliveryAddress(address: AddressModel): Store {
        return {
            street: address.street,
            street_number: Number(address.streetNumber),
            postalCode: Number(address.postalCode),
            country: 'FRANCE',
            city: address.city,
            deliveryType: 'POINT_ADRESSE_LIVRAISON',
            coordinates: {
                lat: address.lat,
                lng: address.lng
            },
            hours: {
                exceptionalClosings: [],
                weekly: {}},
            icons: {

                DEFAULT: this._generateMarkerIconFromSvg(shapeIconHomeLocation, new google.maps.Size(32, 32)),

                SELECTED: this._generateMarkerIconFromSvg(shapeIconHomeLocation, new google.maps.Size(50, 50))
            },
            isDeliveryAddress: true
        };
    }

    protected generateStoreFromData(rawStoreData: BasicObject): IStoreCheckout {
        const {latitude, longitude} = rawStoreData.adresse.coordonneesGps;
        return {
            rvc: rawStoreData.codeSecteurLivraison,
            id: rawStoreData.idPointRelais,
            listIndex: rawStoreData.listIndex,
            name: rawStoreData.libelle,
            reseauLivraison: rawStoreData.reseauLivraison,
            distanceFromAdress: this._calculateDistanceFromLocation(rawStoreData.distanceLocalisation),
            coordinates: {
                lat: +latitude,
                lng: +longitude
            },
            street: rawStoreData.adresse.rue,
            street_number: rawStoreData.adresse?.num,
            postalCode: rawStoreData.adresse.cp,
            country: 'FRANCE',
            city: rawStoreData.adresse.ville,
            hours: this._generateHoursFromData(rawStoreData),
            storeType: rawStoreData.estUneConsigne ? STORE_TYPE.CONSIGNE : STORE_TYPE.RELAIS,
            icons: {
                 
                DEFAULT: this._generateMarkerIconFromSvg(rawStoreData.estUneConsigne ? shapeIconDepositSelected : shapeIconDeposit, new google.maps.Size(32, 32)),
                 
                SELECTED: this._generateMarkerIconFromSvg(rawStoreData.estUneConsigne ? shapeIconDepositSelected : shapeIconDeposit, new google.maps.Size(50, 50))
            },
            deliveryType: 'POINT_RELAIS_EXPRESS',
        };
    }


    private _getStoreRelais(longitude: number,latitude: number,network: string): Observable<IStoreCheckout[]>{
        return this.oauth2Resource
            .pointRelais()
            .addCache()
            .setLocalService()
            .useSalesApi()
            .setParams({
                latitude,
                longitude,
                reseauLivraison: network
            })
            .get()
            .pipe(
                map((data: any) => (data.items || []).map(
                    (store, index) => this.generateStoreFromData({...store, listIndex: index})
                )),
                catchError(error => {
                    console.warn(error);
                    return of([]);
                })
            );
    }

    private _calculateDistanceFromLocation(distance: string): number {
        const dist: number = +(+distance / 1000).toString().slice(0, 3);
        return dist === 0 ? (+distance / 100) : dist;
    }

    private _generateHoursFromData(rawStoreData: BasicObject): StoreHours {
        const weekly: BasicObject = {};
        rawStoreData.horairesHabituels.forEach((day: { jourSemaine: string; creneaux: BasicObject[] }) => {
            const dayIndex: number = Object.values(WEEK_DAYS).findIndex(aDay => aDay.toUpperCase() === day.jourSemaine);
            const afterNoonHour: string = day?.creneaux && day?.creneaux[1] ?
                ` / ${day.creneaux[1].heureDebut}-${day.creneaux[1].heureFin}` : '';
            weekly[dayIndex + 1] = {
                dayOpeningHours: day?.creneaux ? `${day.creneaux[0].heureDebut}-${day.creneaux[0].heureFin}${afterNoonHour}` : 'Fermé',
                dayName: day.jourSemaine.toLocaleLowerCase()
            };
        });
        return {
            weekly,
            exceptionalClosings: []
        };
    }

    private _generateMarkerIconFromSvg(svgData: {name: string; data: string}, size: google.maps.Size):
    {url: string; size: any; scaledSize: any; iconName: string} {
        const svgElement: DocumentFragment = window.document.createRange().createContextualFragment(svgData.data);
        const serializedSVG = new XMLSerializer().serializeToString(svgElement);
        const base64Data = window?.btoa(serializedSVG);
        return {
            url: `data:image/svg+xml;base64,${base64Data}`,
            size,
            scaledSize: size,
            iconName: 'shape-'.concat(svgData.name.replace(/_/g, '-'))
        };
    }
}

