import { StorageData } from '@repositories/storages/storage';

enum KEYS {
    DAD = 'dad',
}

export class OpportunityStorage extends StorageData {

    public static KEYS: typeof KEYS = KEYS;

    protected static prefix = 'opportunity_';

    protected static sessionStorageKeys: string[] = Object.values(KEYS);

}
