import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OrderSummary } from '@interfaces/checkout/cart.interface';
import { AbandonedCartModel } from '@models/abandoned-cart.model';
import { OrderModel } from '@models/order/order.model';
import { PaymentResponseModel } from '@models/payment-response.model';
import { OrderRepository } from '@repositories/order.repository';
import { SalesForceRepository } from '@repositories/sales-force.repository';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PRINCIPAL_OFFER_TYPE } from '../constants/order';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    private orderSuccess$ = new BehaviorSubject<boolean>(false);
     
    public orderSuccessObs$ = this.orderSuccess$.asObservable();

    constructor(
        private orderRepository: OrderRepository,
        private readonly salesForceRepository: SalesForceRepository
    ) { }

    public getCommercialOrdersOfPersonne(idPerson: string): Observable<OrderModel[]> {
        return this.orderRepository.getCommercialOrdersOfPersonne(idPerson);
    }

    public getCommercialOrder(orderId: string): Observable<OrderModel> {
        return this.orderRepository.getCommercialOrder(orderId);
    }

    public getPanierRetoursRepriseParcours(orderId: string): Observable<OrderModel> {
        return this.orderRepository.getPanierRetoursRepriseParcours(orderId);
    }

    public getAbandonedCart(idPerson: string): Observable<AbandonedCartModel[]> {
        return this.orderRepository.getAbandonedCart(idPerson);
    }

    public getOrderData(orderId: number): Observable<OrderModel[]> {
        return this.orderRepository.getOrderData(orderId);
    }

    public notityHybridPaymentConversion(orderId: string): Observable<PaymentResponseModel> {
        return this.orderRepository.notityHybridPaymentConversion(orderId);
    }

    public getFaiOrders(idPerson: string): Observable<OrderModel[]> {
        const orderRecovery = this.salesForceRepository.getData()?.order;
        const obs = orderRecovery ? of([orderRecovery]) : this.getCommercialOrdersOfPersonne(idPerson);
        return obs
            .pipe(map((orders: OrderModel[]) => orders
                .filter((order) => (
                    order.mainOfferType === PRINCIPAL_OFFER_TYPE.ACQUISITION_FIXE ||
                    order.mainOfferType === PRINCIPAL_OFFER_TYPE.MIXED) &&
                    !order.reusable)
                .filter((order) => ['FINALISE', 'ANNULE'].every(status => status !== order.status))
                .filter((order) => ((Date.now() - order.orderCreation.getTime()) / (1000 * 3600 * 24)) < 90)
            ));
    }

    public resolve(
        route: ActivatedRouteSnapshot,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        state: RouterStateSnapshot
    ): Observable<OrderModel> {
        return this.getOrderData(route.params.orderId).pipe(
            map((orders: OrderModel[]) =>
                orders.find((order) => order.id === route.params.orderId)
            ),
            tap((order) => {
                if (!order) { throw new Error('Commande not found'); }
            })
        );
    }

    public getOrderSummary(cartId: number, paymentType: string, isPaymentFmsDiffered?: boolean): Observable<OrderSummary> {
        return this.orderRepository.getOrderSummary(cartId, paymentType, isPaymentFmsDiffered);
    }

    public orderSucceeded(orderSuccess = false): void {
        this.orderSuccess$.next(orderSuccess);
    }
}
