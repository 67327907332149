import { Pipe, PipeTransform } from '@angular/core';
import { SortObjByProp } from '../helper/utils';

@Pipe({
    name: 'sortObjectBy',
    standalone: false
})
export class SortObjectByPipe implements PipeTransform {
    public transform<T>(items: T[], field: string, reverse: boolean): T[] {

        if (!items || !items.length || !field) {
            return items;
        }

        items.sort((a: T, b: T) => SortObjByProp(a, b)(field));
        return reverse ? items.reverse() : items;
    }

}
