import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class CookieStorageService extends CookieService implements Storage {
    public length = 1;

    public clear(): void {
        this.length = 0;
    }

    public getItem(key: string): string | null {
        const data: any = this.get(key);
        if (data) {
            return `{"data": ${data}, "expiresTimestamp": null}`;
        }
        return null;
    }

    public key(): string | null {
        return null;
    }

    public removeItem(key: string): void {
        return this.delete(key, '/');
    }

    public setItem(key: string, value: string): void {
        const data: any | string = JSON.stringify(JSON.parse(value).data);
        return this.set(key, data, null, '/');
    }
}
