import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';

export class CustomFormControl extends UntypedFormControl {

    public readonlyChange: Observable<boolean>;

    private _readonlySubject = new BehaviorSubject<boolean>(false);

    constructor(...args) {
        super(...args);
        this.readonlyChange = this._readonlySubject.asObservable();
    }

    public setReadonly(value: boolean): void {
        this._readonlySubject.next(value);
    }
}
