import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '../../../interfaces/store-locator.interface';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'bytel-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss'],
    standalone: false
})
export class StoreComponent implements OnInit, OnDestroy {

    // Allows generic props for template no matter what the store is
    @Input() public store: Store;
    @Input() public isSelected: boolean;
    @Input() public isLast: boolean;
    @Input() public isStandAlone: boolean;
    @Input() public shouldAvoidScrollDelay = false;

    @Output() selectStoreEvent: EventEmitter<Store> = new EventEmitter<Store>();

    public showHours: boolean;
    public currentDay: number = new Date().getDay();
    public isMobileMQ = false;
    public mq: MediaQueryList;
    private readonly MOBILE_BREAKPOINT: string = '(max-width: 768px)';

    constructor(private ngZone: NgZone) {
        this.mq = window.matchMedia(this.MOBILE_BREAKPOINT);
        this._mediaQueryListListener = this._mediaQueryListListener.bind(this);
    }

    public ngOnInit(): void {
        this._initBreakpointMonitoring();
    }

    public ngOnDestroy(): void {

        this.mq.removeEventListener('change', this._mediaQueryListListener);
    }

    public selectStore(): void {
        this.selectStoreEvent.emit(this.store);
    }

    private _initBreakpointMonitoring(): void {
        this.isMobileMQ = this.mq.matches;

        this.mq.addEventListener('change', this._mediaQueryListListener, { passive: this.shouldAvoidScrollDelay });
    }

    private _mediaQueryListListener(evt: any): void {
        if (evt.matches !== this.isMobileMQ) {
            this.ngZone.run(() => {
                this.isMobileMQ = evt?.matches;
            });
        }
    }
}
