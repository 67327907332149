import { Component, OnDestroy } from '@angular/core';
import { CUSTOMER_CATEGORY, Plan, Product, QUOTE_CONTEXTS } from '@bytel/bytel-sales';
import { ALLOWED_CATEGORIES } from '@interfaces/api/catalog.interface';
import { IContextualizedProductsParams, ISapicProduct, PRODUCT_TYPES } from '@interfaces/products.interface';
import { CategoryWithProductModel } from '@models/category.model';
import { CatalogType } from '@repositories/sales.repository';
import { CatalogService } from '@services/catalog.service';
import { CustomerService } from '@services/customer/customer.service';
import { Subject, catchError, map, switchMap } from 'rxjs';

interface IQueryParamsFromFiltersForm {
    tri: string;
    limite: number;
    decalage: number;
    categorie?: string;
    stock: string;
    modePourFinancement: ModeFinancementEnum;
    selectedPlan?: string;
    prixReferencePourTri?: string;
    typeSim?: string;
    connectivite?: string;
    nom?: string;
}

enum PrixReferenceEnum {
    INITIAL = 'initial',
    SUBVETIONNE = 'subventionne',
    POUR_TOUJOURS = 'pourToujours',
    FINAL = 'final',
    APPORT_INITIAL = 'apportInitial',
    MONTANT_MENSUALITE = 'montantMensualite'
}

export enum ModeFinancementEnum {
    TOUS = 'tous',
    AUTO = 'auto',
    MIN_ONE_OFF = 'min-one-off'
}

export interface IFilterPlanParams {
    name: string;
}

export interface IFilterEquipmentParams {
    name: string;
    simType: string;
    connectivity: string;
    sortBy: string;
    selectedPlanGencode: string;
    isSortedByPremiumPlan: boolean;
}

export interface IDevicesProducts {
    raw: ISapicProduct[];
    products: Product[];
}

type QueryParams = Record<string, string | number>;

@Component({
    selector: 'tlv-products-walls',
    templateUrl: './products-walls.component.html',
    styleUrl: './products-walls.component.scss',
    standalone: false
})
export class ProductsWallsComponent implements OnDestroy {

    public static PRODUCTS_FILTER_TYPE = PRODUCT_TYPES;
    public static DEFAULT_CATEGORY: string = ALLOWED_CATEGORIES.PHONE_TELESALES;
    public readonly availableCategories = new Map<ALLOWED_CATEGORIES, PRODUCT_TYPES>([
        [
            ALLOWED_CATEGORIES.FAIM_TELESALES,
            PRODUCT_TYPES.PLAN
        ],
        [
            ALLOWED_CATEGORIES.TOP_TELESALES,
            PRODUCT_TYPES.PHONE
        ],
        [
            ALLOWED_CATEGORIES.PHONE_TELESALES,
            PRODUCT_TYPES.PHONE
        ],
        [
            ALLOWED_CATEGORIES.PLAN_PREMIUM_TELESALES,
            PRODUCT_TYPES.PLAN
        ],
        [
            ALLOWED_CATEGORIES.PLAN_SOWO_TELESALES,
            PRODUCT_TYPES.PLAN
        ],
        [
            ALLOWED_CATEGORIES.FAIM_PREMIUM_TELESALES,
            PRODUCT_TYPES.PLAN
        ],
        [
            ALLOWED_CATEGORIES.DEVICE_FAIM_TELESALES,
            PRODUCT_TYPES.DEVICE
        ],
        [
            ALLOWED_CATEGORIES.PROMPTO_TELESALES,
            PRODUCT_TYPES.PLAN
        ],
        [
            ALLOWED_CATEGORIES.SIMOFAI_TELESALES,
            PRODUCT_TYPES.PLAN
        ]
    ]);
    public productType: PRODUCT_TYPES;
    public currentCategory: CategoryWithProductModel;
    public PRODUCT_TYPES = PRODUCT_TYPES;
    public isLoadingProducts = false;
    public isLoadingMoreProducts = false;
    public devices: IDevicesProducts = {raw: [], products: []};
    public plans: Plan[] = [];
    public totalProductCount = 0;
    public isSortedByPremiumPlan = true;
    public hasScoringErrors = false;
    public hasProductLoadFailed = false;

    private _queryParams: IQueryParamsFromFiltersForm;
    private _searchSubject: Subject<{query: QueryParams; isIncremental: boolean}> =
        new Subject<{query: QueryParams; isIncremental: boolean}>();

    constructor(
        private catalogService: CatalogService,
        private customerService: CustomerService
    ){
        this._searchSubject.pipe(
            switchMap((params: {query: QueryParams; isIncremental: boolean}) =>
                this.catalogService.getContextualizedProducts(
                    params.query, this._generateBodyParamsFromCart(this._queryParams.selectedPlan)
                ).pipe(
                    catchError(() => {
                        this.isLoadingProducts = false;
                        this.hasProductLoadFailed = true;
                        return [];
                    }),
                    map((data)=>{
                        this.hasProductLoadFailed = false;
                        return {response: data, isIncremental: params.isIncremental};
                    })
                )
            )
        ).subscribe({
            next: ({response, isIncremental}) => {
                if (isIncremental) {
                    this.devices = {
                        raw: [...this.devices.raw, ...response.raw],
                        products: [...this.devices.products, ...response.products]
                    };
                } else {
                    this.devices = {
                        raw: response.raw,
                        products: response.products
                    };
                }
                this.totalProductCount = response.totalCount;
                this.isLoadingMoreProducts = false;
                this.isLoadingProducts = false;
            },
            error: () => this.isLoadingProducts = false
        });
    }

    public ngOnDestroy(): void {
        this._searchSubject.unsubscribe();
    }

    public onCategoryChange(category: CategoryWithProductModel): void {
        this.totalProductCount = 0;
        this.currentCategory = category;
        this.productType = this.availableCategories.get(this.currentCategory?.code as ALLOWED_CATEGORIES);

        this.isLoadingProducts = true;
        if (this.productType !== PRODUCT_TYPES.PLAN) {
            this._updateDevicesProducts();
        }
    }

    public onFiltersFormChange(event: IFilterEquipmentParams | IFilterPlanParams): void {
        if (this.productType !== PRODUCT_TYPES.PLAN) {
            const filtersParams = event as IFilterEquipmentParams;
            this.isSortedByPremiumPlan = filtersParams.isSortedByPremiumPlan;
            this._queryParams = this._generateQueryParamsFromForm(filtersParams);
            this._updateDevicesProducts();
        }
    }

    public updateDevicesOnPageScroll(): void {
        if (this.totalProductCount === this.devices.products.length) {
            return;
        }
        this._updateDevicesProducts(true);
    }

    public reloadProducts(): void {
        this._updateDevicesProducts();
    }

    private _updateDevicesProducts(onPageScroll = false): void {

        if (!this._queryParams) {
            this.isLoadingProducts = false;
            return;
        }
        this.isLoadingProducts = !onPageScroll;

        let decalage = 0;
        if (onPageScroll) {
            const offset: number = this.devices.products.length;
            decalage = offset;
            this.isLoadingMoreProducts = true;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {selectedPlan, ...queryParams} = this._queryParams;
        const query = {
            ...queryParams,
            decalage,
        };

        this._searchSubject.next({query: query as any, isIncremental: onPageScroll});
    }

    private _generateBodyParamsFromCart(selectedPlan: string): IContextualizedProductsParams {
        return {
            panier: {
                parcours: [
                    {
                        type: QUOTE_CONTEXTS.ACQUISITION,
                        produits: [{...(this.isSortedByPremiumPlan ? {gencode: selectedPlan} : {}), catalogue: CatalogType.BYTEL}],
                        estCourant: true
                    }
                ],
                client: {
                    ...(this.customerService.customer?.idPerson ?
                        { idPersonne: this.customerService.customer?.idPerson }
                        : { categorie: this.customerService.isCustomerOrProspectPro() ? CUSTOMER_CATEGORY.PRO : CUSTOMER_CATEGORY.GP }),
                }
            }
        };
    }

    private _generateQueryParamsFromForm(formData: IFilterEquipmentParams): IQueryParamsFromFiltersForm {
        return {
            tri: formData.sortBy,
            limite: 20,
            decalage: 0,
            categorie: this.currentCategory.code,
            stock: 'enStock',
            modePourFinancement: ModeFinancementEnum.AUTO,
            selectedPlan: formData.selectedPlanGencode,
            prixReferencePourTri: PrixReferenceEnum.INITIAL,
            ...(formData.name ? {nom: formData.name} : {}),
            ...(formData.connectivity ? {connectivite: formData.connectivity} : {}),
            ...(formData.simType ? { typeSim: `${formData.simType},hybride` } : {})
        };
    }
}
