import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FaiRepository } from '@repositories/fai.repository';
import { FAI_CART_TECHNO, FAI_CART_TYPE } from '../../constants/fai';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { catchError, concatMap, last, map, mergeMap, tap } from 'rxjs/operators';
import { AddressModel } from '@models/cart/address.model';
import { FaiAddressModel } from '@models/fai/fai-address';
import { SalesForceRepository } from '@repositories/sales-force.repository';
import { SaleForceInfosModel } from '@models/sale-force-infos.model';

@Injectable({
    providedIn: 'root'
})
export class FaiFunnelService {

    public faiCart: Observable<FaiCartModel>;

    constructor(
        private faiRepository: FaiRepository,
        private salesForceRepository: SalesForceRepository
    ) { }

    public setTechno(techno: FAI_CART_TECHNO): void{
        this.faiCart = this.faiRepository.createCart(FAI_CART_TYPE.ADDRESS,techno)
            .pipe(last(),catchError(()=>{
                this.faiCart = null;
                throw new Error('Fail to set TECHNO');
            }));
    }

    public createCart(techno: FAI_CART_TECHNO): Observable<void> {
        return this.faiRepository.createCart(FAI_CART_TYPE.ADDRESS, techno)
            .pipe(
                last(),
                map(cart => this.faiCart = of(cart)),
                map(()=>null),
                catchError(()=>{
                    this.faiCart = null;
                    throw new Error('Fail to set TECHNO');
                }));
    }

    public getStreetNumber(
        postalCode: string, cityCode: string, streetCode: string
    ): Observable<{code: string;libelle: string}[]>{
        return this.faiCart.pipe(
            mergeMap((cart)=>this.faiRepository.funnel(
                cart,{postalCode,cityCode,streetCode}
            )),
            map((data)=>data.codeNumeroVoie)
        );
    }

    public getStreet(postalCode: string, cityCode: string, search: string): Observable<{code: string;libelle: string}[]> {
        return this.faiCart.pipe(
            mergeMap((cart)=>this.faiRepository.funnel(
                cart,{postalCode,cityCode},search
            )),
            map((data)=>data.codeVoie)
        );
    }

    public getCity(postalCode: string): Observable<{code: string;libelle: string}[]>  {
        return this.faiCart.pipe(
            mergeMap((cart)=>this.faiRepository.funnel(
                cart,{postalCode}
            )),
            map((data)=>data.codeInsee)
        );
    }

    public getPostalCode(postalCode: string): Observable<string[]> {
        return this.faiCart.pipe(
            mergeMap((cart)=>this.faiRepository.funnel(
                cart,{postalCode}
            )),
            map((data)=>data.codeInsee?.length ? [postalCode] : data.codePostal || [])
        );
    }

    public getComplement(
        postalCode: string, cityCode: string, streetCode: string, streetNumber: string
    ): Observable<{code: string;libelle: string}[] > {
        return this.faiCart.pipe(
            mergeMap((cart)=>this.faiRepository.funnel(
                cart,{postalCode,cityCode,streetCode},streetNumber
            )),
            map((data)=>data.extensions)
        );
    }

    public playAllFunnelSteps(
        address: AddressModel,
        faiCart: FaiCartModel,
    ): Observable<FaiAddressModel> {

        const orderRecovery: SaleForceInfosModel = this.salesForceRepository.getData();
        const backupCurrentCart: Observable<FaiCartModel> = this.faiCart;
        this.faiCart = of(faiCart);
        const faiAddress: FaiAddressModel = new FaiAddressModel({});

        return this.getPostalCode(address.postalCode)
            .pipe(
                tap((codes: string[]) => {
                    faiAddress.postal = codes.find(code => code === address.postalCode);
                    if (!faiAddress.postal){ throw new Error('Code postal inconnue');}
                }),
                concatMap(() =>this.getCity(faiAddress.postal)),
                tap((cities: {code: string; libelle: string}[]) => {
                    if (orderRecovery?.order?.fai) {
                        faiAddress.city = cities.find(code => code.code === orderRecovery?.order?.fai?.address?.city?.code);
                    }
                    faiAddress.city = faiAddress.city ?? cities.find(
                        city => address.city.toLowerCase().startsWith(city.libelle.toLowerCase())
                    );
                    if (!faiAddress.city){ throw new Error('Ville inconnue'); }
                }),
                concatMap(() =>
                    this.getStreet(faiAddress.postal, faiAddress.city.code, orderRecovery ? null : address.street?.split(' ').pop())
                ),
                tap((codeVoies: {code: string; libelle: string}[]) => {
                    if (orderRecovery?.order?.fai) {
                        faiAddress.street = codeVoies.find(code => code.code === orderRecovery?.order?.fai?.address?.street?.code);
                    }
                    faiAddress.street = faiAddress.street ?? codeVoies.find(
                        code => code.libelle.toLowerCase().includes(address.street.split(' ').pop().toLowerCase())
                    );
                    if (!faiAddress.street){ throw new Error('Voie inconnue');}
                }),
                concatMap(()=>{
                    return this.getStreetNumber(faiAddress.postal,faiAddress.city.code,faiAddress.street.code).pipe(
                        tap((streetNumbers: {code: string;libelle: string}[]) => {
                            const streetNumberData = streetNumbers
                                .find(streetNumber => streetNumber.libelle === address.streetNumber);
                            faiAddress.street_number = streetNumberData?.libelle;
                            faiAddress.streetNumberCode = streetNumberData?.code;
                            if (!faiAddress.street_number){ throw new Error('NumÃ©ro de voie inconnue');}
                        })
                    );
                }),
                concatMap(() =>
                    address.complement ?
                        this.getComplement(faiAddress.postal, faiAddress.city.code, address.street, address.streetNumber) :
                        of(null)
                ),
                concatMap(complement => {
                    if (!complement){return of(faiAddress);}
                    faiAddress.complement =
                        complement.find(code => code.libelle.toLowerCase().includes(address.complement.toLowerCase()));
                    if (!faiAddress.complement){ throw new Error('complement inconue');}
                    return of(faiAddress);
                }),
                map(() => {
                    this.faiCart = backupCurrentCart;
                    return faiAddress;
                })
            );
    }

    public getFaiAddress(address: AddressModel, techno: FAI_CART_TECHNO = FAI_CART_TECHNO.MONO_TECHNO): Observable<FaiAddressModel> {
        return this.createCart(techno)
            .pipe(
                mergeMap(() => this.faiCart),
                mergeMap(cart => this.playAllFunnelSteps(address, cart))
            );
    }
}
