export enum PRINCIPAL_OFFER_TYPE {
    RENOUVELLEMENT = 'RENOUV',
    ACQUISITION_FIXE = 'FAI',
    ACQUISITION_FIXE_TECHNO_MOBILE = 'FAIM UNLIMITED',
    ABONNEMENT_NU = 'B&YOU',
    SIMO = 'SIMO',
    ACQUISITION_SUBVENTIONNEE = 'SENSATION',
    TERMINAL_NU = 'TX_NU',
    ACCESSOIRE = 'ACCESSOIRE',
    MIXED = 'SIMO & FAI',
}

export enum PRINCIPAL_OFFER_TYPE_QUOTE_MIXED {
    ACQUISITION_FIXE = 'FAI',
    SIMO = 'SIMO',
    ABONNEMENT_NU = 'B&YOU',
}

export enum CHANNEL {
    TLV = 'TLV',
    WEB = 'WEB',
    RCBT = 'RCBT',
    OTHER = 'DEFAULT'
}

export enum ORDER_STATUS {
    CANCELED = 'ANNULE',
    VALIDATE = 'PAIEMENT_VALIDE',
    NOT_COMPLETE = 'NON_FINALISE'
}

export enum CHANNEL_LABEL {
    TLV = 'TLV',
    TELESALES = 'TLV', /* eslint-disable-line */
    WEB = 'WEB',
    RCBT = 'BOUTIQUE'
}

export enum ACTOR_CREATOR_LABEL {
    CDV = 'VENDEUR',
    CDC = 'TÉLÉVENDEUR',
    TLV = 'TÉLÉVENDEUR', /* eslint-disable-line */
    PERSONNE = 'CLIENT',
}

export enum ORDER_ELEMENT_TYPE {
    OFFRE_PRINCIPALE = 'OFFRE_PRINCIPALE',
    OFFRE_LIVRAISON = 'OFFRE_LIVRAISON',
    FRAIS = 'FRAIS',
    LIVRAISON = 'LIVRAISON',
    REMISE_ABONNEMENT = 'REMISE_ABONNEMENT'
}
