import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ISCORING_ACTIONS } from '@interfaces/scoring.interface';
import { DialogRef } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SalesUserService } from '@services/sales-user.service';
import {finalize, tap} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { MainCartModel } from '@models/cart/main-cart.model';
import {Product, ProductFactory, Edp, Sim, Prices} from '@bytel/bytel-sales';
@Component({
    selector: 'tlv-scoring-modal',
    templateUrl: './scoring-modal.component.html',
    styleUrls: ['./scoring-modal.component.scss'],
    standalone: false
})
export class ScoringModalComponent {

    public scoringActions = ISCORING_ACTIONS;
    public afterClosedSubscription: Subscription;
    public choice = false;
    public isLoading = false;
    public cartModel: MainCartModel;

    constructor(
        public ref: DialogRef,
        public salesUserService: SalesUserService,
        public cartService: CartTeleSalesService,
        public router: Router,
        @Inject(DOCUMENT) private document: any
    ) {

        this.cartModel = this.cartService.cartModel.clone() as MainCartModel;
        // On récupère les prix des produits du quote (le clone ne clone pas réellement)
        this.cartService.cartModel.quotes.forEach(
            (q,indexQ) => {
                this.cartModel.quotes[indexQ].totals = q.totals;
                q.products.map(
                    (p, indexP) => {
                        this.cartModel.quotes[indexQ].products[indexP].prices.base = p.prices.base;
                        this.cartModel.quotes[indexQ].products[indexP].prices.final = p.prices.final;
                        this.cartModel.quotes[indexQ].products[indexP].prices.forever = p.prices.forever;
                        this.cartModel.quotes[indexQ].products[indexP].prices.duration = p.prices.duration;
                    }
                );
            }
        );

        this._removeEdpScoringCart();
    }

    public removeEdpAndPay(): void {
        this.isLoading = true;
        this.cartService.cartModel.setFundingMethod('CASH');
        this.cartService.removeEdpFromScoring(
            this.cartService.cartModel.getQuote()?.getProductByType<Edp>('Edp'),
            this.cartService.cartModel.currentQuoteIndex,
            true
        ).pipe(
            finalize(() => this.isLoading = false),
            tap(() => this.ref.close({ACTION: 'FINISH_ORDER'}))
        ).subscribe();
    }

    public closeModalAndRedirectCart(): void {
        this.ref.close();
        this.router.navigate(['panier']);
    }

    public endCall(): void {
        this.salesUserService.closeCall();
    }

    public hasEdpKo(): boolean {
        return !!this.ref.data.scoringError.find((data) => !!data.edp);
    }

    public isQuoteMixed(): boolean {
        return this.cartService.isQuoteMixed();
    }

    private _removeEdpScoringCart(): void {
        const quoteIndex: number = this.cartModel.currentQuoteIndex;
        let priceSimBeforeRefresh = 0;
        // le remove product nous vire les frais de ports
        this.cartModel.quotes[quoteIndex].products = this.cartModel.quotes[quoteIndex].products
            .filter((p: Product) => !ProductFactory.Is(p, Edp));

        // on stock le prix de la sim car elle sera modifié via le refresh
        const sim = this.cartModel.getQuote().getProductByType(Sim);
        if (sim) {
            priceSimBeforeRefresh = sim?.prices?.base;
        }
        this.cartService.getCartService().refresh(this.cartModel)
            .finally( () => {
                const simClone = this.cartModel.getQuote().getProductByType(Sim);
                if (simClone) {
                    // on stock le prix de la sim modifié pour le soustraire du total
                    const priceSimAfterRefresh = this.cartModel.getQuote().getProductByType(Sim).prices.base;
                    simClone.prices = new Prices(priceSimBeforeRefresh);
                    this.cartModel.totals.daily.final -= priceSimAfterRefresh;
                }
            });
    }
}
